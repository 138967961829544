import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { MAX_DECIMALS } from 'utils/constants'
import { generateDeviceData } from 'utils/deviceDataGenerator'

import GaugeTemplate from './GaugeTemplate'
import messages from './messages'
import {
  manageWSSubscriptions,
  generatePartialStateFromProps,
  getValueTypes,
  getDecimals,
  withDefaultValues
} from '../utils'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
})

class GaugeSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const { node, dinamicData } = partialState
    const widgetData = withDefaultValues(props.data, GaugeTemplate.content.params)
    const { minValue, maxValue, numberOfDecimals } = widgetData

    this.state = {
      ...partialState,
      minValue,
      minValueError: false,
      maxValue,
      maxValueError: false,
      numberOfDecimals,
      numberOfDecimalsErrorText: ''
    }
  }

  handleChange = name => event => {
    let change = { [name]: event.target.value }
    if (name === 'numberOfDecimals') change = { ...change, [name + 'ErrorText']: '' }
    this.setState(change)
  }

  handleDinamicChange = (event, value) => {
    const { signalId = '' } = value || {}
    this.setState(state => {
      const { node, valueType } = state
      const valueTypes = getValueTypes(node, signalId)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      const change = {
        dinamicData: signalId,
        numberOfDecimals: 0,
        numberOfDecimalsErrorText: '',
        valueTypes,
        valueType: newValueType
      }
      return change
    })
  }

  changeSelectedDevice = device => {
    this.setState({
      deviceEid: [device.EID],
      originalDevice: device,
      node: generateDeviceData(device),
      dinamicData: '',
      valueTypes: [],
      valueType: '',
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        originalDevice: nodeWithConfig,
        configurationLoading: false
      }
    })
  }

  handleChangeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleSaveSettings = () => {
    let error = false

    const { deviceEid, minValue, maxValue, numberOfDecimals, node, dinamicData } = this.state

    if (deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (minValue === '') {
      error = true
      this.setState({
        minValueError: true
      })
    }

    if (maxValue === '') {
      error = true
      this.setState({
        maxValueError: true
      })
    }

    if (numberOfDecimals === '') {
      error = true
      this.setState({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else if (numberOfDecimals < 0) {
      error = true
      this.setState({
        numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
      })
    } else {
      const decimals = getDecimals(node, dinamicData) || MAX_DECIMALS
      if (numberOfDecimals > decimals) {
        error = true
        this.setState({
          numberOfDecimalsErrorText: this.formatMessage(messages.maxNumberOfDecimalsCantBeHigherThan, { max: decimals })
        })
      }
    }

    if (!error) {
      let value = ''

      const { dinamicData, valueType, wsSubscribedData, node } = this.state
      const { eid, data: propsData, devicesData, saveSettings } = this.props

      if (
        propsData.value &&
        dinamicData !== '' &&
        dinamicData === wsSubscribedData &&
        propsData.valueType === valueType &&
        eid === deviceEid[0]
      ) {
        value = { ...propsData.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }
      const data = {
        data: dinamicData,
        minValue,
        maxValue,
        numberOfDecimals,
        valueType,
        value
      }

      const propsNode = devicesData[deviceEid[0]]
      const deviceInfo = propsNode ? '' : node

      saveSettings(data, deviceEid, deviceInfo)

      // Sockets control parameters
      const { subscribeWidgetToWS, unsubscribeWidgetFromWS, getNodeCredentials, getAzureToken } = this.props

      manageWSSubscriptions(
        this.state,
        data,
        devicesData,
        subscribeWidgetToWS,
        unsubscribeWidgetFromWS,
        getNodeCredentials,
        getAzureToken
      )

      this.setState({
        wsSubscribedData: data.data,
        previousEid: deviceEid
      })
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      node: { staticData }
    } = this.state

    const { classes } = this.props

    const data = staticData.find(({ name }) => name === 'hasConfiguration')

    let messageKey = ''
    let color = 'black'

    if (configurationLoading) {
      messageKey = 'Loading'
      color = '#f0ad4e'
    } else {
      if (data?.value) {
        messageKey = 'Available'
        color = 'green'
      } else {
        messageKey = 'NotAvailable'
        color = 'red'
      }
    }

    const configurationStatus = this.formatMessage(messages['configuration' + messageKey])

    return (
      <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
        <strong>{this.formatMessage(messages.configurationStatus)}: </strong>{' '}
        <span style={{ color }}>{configurationStatus}</span>
      </DialogContentText>
    )
  }

  render() {
    const { classes } = this.props
    const {
      deviceEid,
      valueTypes,
      configurationLoading,
      devicesButtonTextKey,
      devicesTableDisplayed,
      node,
      valueType,
      minValueError,
      minValue,
      maxValueError,
      maxValue,
      numberOfDecimalsErrorText,
      numberOfDecimals,
      dinamicData
    } = this.state
    const selectedDeviceEid = deviceEid.length > 0 ? deviceEid[0] : ''
    const needsValueType = valueTypes.length > 0
    const noDinamicDataMessageKey = configurationLoading ? 'loadingConfiguratedSignals' : 'notSupportedMachine'
    const options = node.dinamicData.map(signal => {
      let signalType = this.formatMessage(messages.canBusSignals)
      if (signal.isGPS) signalType = this.formatMessage(messages.gpsSignals)
      else if (signal.isMFIO) signalType = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalType }
    })

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.gaugeWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.devicesButton }}
            onClick={this.handleChangeDevicesTableDisplay}
          >
            {this.formatMessage(messages[devicesButtonTextKey])}
          </Button>
          <br />
          {this.renderConfigurationStatus()}
          <br />
          {devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          <br />
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.gaugeWidgetSettings)}
            </span>
          </DialogContentText>

          {options.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel }}
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  id='grouped-signals'
                  onChange={this.handleDinamicChange}
                  options={options}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={options.find(signal => signal.signalId === dinamicData) || null}
                />
              </Grid>
              {needsValueType && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='valueType-label-placeholder' shrink>
                      {this.formatMessage(messages.valueType)}
                    </InputLabel>
                    <Select onChange={this.handleChange('valueType')} value={valueType}>
                      {valueTypes.map(type => {
                        return (
                          <MenuItem key={type} value={type}>
                            {this.formatMessage(messages[type])}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages[noDinamicDataMessageKey])}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherGaugeWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                error={minValueError}
                fullWidth
                helperText={minValueError ? this.formatMessage(messages.mustHaveAValue) : null}
                id='minValue'
                label={this.formatMessage(messages.minValue)}
                margin='normal'
                onChange={this.handleChange('minValue')}
                required
                type='text'
                value={minValue}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={maxValueError}
                fullWidth
                helperText={maxValueError ? this.formatMessage(messages.mustHaveAValue) : null}
                id='maxValue'
                label={this.formatMessage(messages.maxValue)}
                margin='normal'
                onChange={this.handleChange('maxValue')}
                required
                type='text'
                value={maxValue}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={numberOfDecimalsErrorText !== ''}
                fullWidth
                helperText={numberOfDecimalsErrorText}
                id='numberOfDecimals'
                inputProps={{ min: 0, max: 5 }}
                label={this.formatMessage(messages.maxNumberOfDecimals)}
                margin='normal'
                onChange={this.handleChange('numberOfDecimals')}
                required
                type='number'
                value={numberOfDecimals}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={configurationLoading} onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

GaugeSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getNodeCredentials: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(GaugeSettings))
