import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { mapToHierarchicalGroups } from 'components/Template/Header/ProfileDropdown/utils'
import Loading from 'components/Loading'
import messages from './messages'

const styles = makeStyles(theme => ({
  divider: {
    margin: '11px 0px'
  },
  menuHeader: {
    color: '#5E5D52',
    display: 'flex',
    padding: '11px 16px 6px',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: '#5E5D52',
    fontFamily: 'Roboto',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px'
  },
  menuItemIcon: {
    color: '#009541',
    marginRight: '8px'
    // marginLeft: '8px'
  },
  dialogOuterClass: {
    width: '50vw',
    height: '75vh',
    maxWidth: '90%',
    maxHeight: '80%'
  },
  customScrollList: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '320px',
    scrollbarWidth: 'auto',
    scrollbarColor: '#CCCCCC transparent',
    scrollbarTrackColor: 'transparent',
    scrollbarBaseColor: 'transparent',
    scrollbarFaceColor: '#CCCCCC',
    border: '1px solid #BFBEBE',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      // minHeight: '100%',
      minHeight: '300px'
    }
  },
  flexClass: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '16px'
  },
  marginClass: {
    marginTop: '20px',
    width: '100%'
  },
  dialogClass: {
    width: '50'
  },
  filterTextClass: {
    marginBottom: '2px',
    fontWeight: 500
  },
  filterClass: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.41px',
    textAlign: 'left'
  },
  root: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.41px',
    textAlign: 'left',
    '.MuiOutlinedInput-root': {
      borderRadius: 0
    },
    '& .MuiInputLabel-outlined': {
      borderRadius: 0
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0
    }
  },
  loadingDivClass: {
    height: '100%',
    minHeight: '320px',
    border: '1px solid #BFBEBE '
  },
  italicPlaceholder: {
    '&::placeholder': {
      fontStyle: 'italic'
    }
  }
}))

const ChangeGroupDialog = ({
  openDialog,
  handleDialogClose,
  // classes,
  groups,
  selectedGroup,
  changeGroup,
  disabled,
  intl,
  loadPermissionsOfUserInGroup
}) => {
  const classes = styles()
  const { formatMessage } = intl
  const [searchTerm, setSearchTerm] = useState()
  const [filteredGroups, setFilteredGroups] = useState(groups)
  const [selectedGroupId, setSelectedGroupId] = useState(selectedGroup.id)
  const [isLoading, setIsLoading] = useState(false)
  const lastSelectedGroupId = selectedGroup.id
  const hierarchicalFilteredGroups = mapToHierarchicalGroups(filteredGroups)
  const hierarchicalGroups = mapToHierarchicalGroups(groups)

  const renderHierarchicalGroups = () => {
    return hierarchicalFilteredGroups.map((group, index) => {
      const isCurrentGroup = group.id === selectedGroupId
      const hasNesting = group.depth > 0
      const hasNestingExtraMargin = group.depth > 1
      const nestingExtraMargin = hasNestingExtraMargin ? (group.depth - 1) * 10 : 0
      const nestingTotalMargin = 4 + nestingExtraMargin

      return (
        <MenuItem
          key={index}
          className={classes.menuItem}
          disabled={disabled}
          onClick={() => {
            setSelectedGroupId(group.id)
          }}
          style={isCurrentGroup ? { fontWeight: 'bold', backgroundColor: '#F5F5F5' } : { paddingLeft: 48 }}
          value={group.id}
        >
          {isCurrentGroup && <CheckCircleOutlineIcon className={classes.menuItemIcon} />}
          {hasNesting && (
            <KeyboardArrowRightIcon style={{ fontSize: 18, marginLeft: nestingTotalMargin, marginRight: 4 }} />
          )}
          {group.name}
        </MenuItem>
      )
    })
  }
  const handleSearch = event => {
    const term = event.target.value
    setSearchTerm(term)
    const results = hierarchicalGroups.filter(item => item.name.toLowerCase().includes(term.toLowerCase()))
    setFilteredGroups(results)
  }

  useEffect(() => {
    handleDialogClose()
    setIsLoading(false)
  }, selectedGroup.id)

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        // className={classes.dialogOuterClass}
        PaperProps={{
          style: {
            minWidth: window.innerWidth < window.innerHeight ? '90vw' : '40vw',
            minHeight: window.innerWidth < window.innerHeight - window.innerHeight / 5 ? '28vh' : '50vh',
            maxWidth: '90%',
            maxHeight: '80%',
            paddingBottom: '8px'
          }
        }}
      >
        {isLoading ? (
          <>
            <DialogTitle>{formatMessage(messages.changeGroup)}</DialogTitle>
            <DialogContent style={{ height: '100%', padding: '16px 24px' }}>
              <Grid container justifyContent='center' alignItems='center' className={classes.loadingDivClass}>
                <Grid item>
                  <Loading />
                  <Typography variant='body1'> {formatMessage(messages.loadingGroup)} </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>
              {formatMessage(messages.changeGroup)}
              <IconButton
                onClick={() => {
                  setSearchTerm()
                  setSelectedGroupId(selectedGroup.id)
                  setFilteredGroups(groups)
                  handleDialogClose()
                }}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction='column'
                spacing={2}
                // style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Grid item style={{ flex: '0 0 auto' }}>
                  {' '}
                  <Typography variant='body1' className={classes.filterTextClass}>
                    {formatMessage(messages.filterByName)}
                  </Typography>
                  <TextField
                    id='outlined-basic'
                    placeholder='Enter name'
                    InputProps={{
                      classes: {
                        input: classes.italicPlaceholder
                      }
                    }}
                    size='small'
                    variant='outlined'
                    value={searchTerm}
                    onChange={handleSearch}
                    inputProps={{ style: { borderRadius: '0' } }}
                    classes={{ root: classes.root }}
                  />
                </Grid>
                <Grid item style={{ flex: '1' }}>
                  <div className={classes.customScrollList} style={{ flex: '1' }}>
                    {renderHierarchicalGroups(groups, selectedGroup, changeGroup)}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={classes.flexClass}>
                <Button
                  className='cancel-button'
                  title='Cancel'
                  style={{ padding: '6px 12px' }}
                  onClick={() => {
                    setSearchTerm()
                    setSelectedGroupId(selectedGroup.id)
                    setFilteredGroups(groups)
                    handleDialogClose()
                  }}
                >
                  Cancel
                </Button>
                {lastSelectedGroupId === selectedGroupId ? (
                  <Button
                    disabled
                    className='primary-action-button'
                    title='Confirm'
                    style={{ padding: '6px 12px', marginLeft: '8px' }}
                  >
                    Confirm
                  </Button>
                ) : (
                  <Button
                    className='primary-action-button'
                    title='Confirm'
                    style={{ marginLeft: '8px', padding: '6px 12px' }}
                    onClick={() => {
                      setSearchTerm()
                      setFilteredGroups(groups)
                      changeGroup(selectedGroupId)
                      loadPermissionsOfUserInGroup(selectedGroupId)
                      setIsLoading(true)
                    }}
                  >
                    {' '}
                    Confirm{' '}
                  </Button>
                )}
              </div>
            </DialogActions>{' '}
          </>
        )}
      </Dialog>
    </>
  )
}

ChangeGroupDialog.propTypes = {
  changeGroup: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  groups: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  loadPermissionsOfUserInGroup: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired
}
export default injectIntl(ChangeGroupDialog)
