import { actions as ha } from 'utils/http'

import { getSessionGroupId, getLastGroupId, cacheSessionGroupId, cacheLastGroupId } from './cache'
import * as aT from '../actionTypes'
import * as c from '../constants'

const flattenGroup = (groups, group) => {
  const flatGroup = {
    ...group,
    isRoot: group.parent === undefined // Or depth === 0
  }

  if (group.location) {
    flatGroup.location = {
      longitude: group.location.lng,
      latitude: group.location.lat
    }
  }

  if (group.overlays) {
    flatGroup.overlays = group.overlays.map(({ overlayURL, bounds }) => ({
      url: overlayURL,
      bounds: bounds.map(({ lat, lng }) => ({
        latitude: lat,
        longitude: lng
      }))
    }))
  }

  // Redudant data
  delete flatGroup.children
  delete flatGroup.parent
  delete flatGroup['@objectId']
  delete flatGroup.depth
  delete flatGroup.nodeCount

  if (group.children) {
    groups = group.children.reduce((ret, n) => ({ ...ret, ...flattenGroup(groups, n) }), groups) // eslint-disable-line no-param-reassign
    flatGroup.children = group.children.map(({ hashId }) => hashId)
  }

  return { ...groups, [group.hashId]: flatGroup }
}

const initialState = {
  selectedGroup: {},
  userGroups: [],
  userPermissions: '',
  defaultGroupId: null,
  loadingGroup: false
}

const reducer = (state = initialState, action) => {
  const { selectedGroup, userGroups, userPermissions, ...oldGroups } = state
  switch (action.type) {
    case ha.types.success(ha.types.LOAD_PROFILE):
      return { ...action.payload.data.groups.reduce(flattenGroup, state), selectedGroup, userGroups, userPermissions }

    case ha.types.success(ha.types.GROUP_HIERARCHY):
      return { ...action.payload.data.reduce(flattenGroup, {}), selectedGroup, userGroups, userPermissions }

    case aT.GROUPS_LOADED:
      const data = action.payload.groups
      if (data.length > 0) {
        const activeGroups = data.filter(group => group.status_id === 1)
        const groups = activeGroups.map(group => ({
          id: group.id,
          name: group.group_name,
          updatedAt: group.updatedAt,
          createdAt: group.createdAt,
          parent_id: group.parent_id,
          nodeCount: 0
        }))
        let { id, name } = selectedGroup
        const indexOfCurrent = groups.findIndex(group => group.name === name && group.id === id)
        if (indexOfCurrent === -1) {
          const sessionGroup = groups.find(group => group.id === getSessionGroupId())
          if (typeof sessionGroup === 'undefined') {
            const lastUsedGroup = groups.find(group => group.id === getLastGroupId())
            if (typeof lastUsedGroup === 'undefined') {
              id = groups[0].id
              name = groups[0].name
            } else {
              id = lastUsedGroup.id
              name = lastUsedGroup.name
            }
          } else {
            id = sessionGroup.id
            name = sessionGroup.name
          }
          cacheSessionGroupId(id)
          cacheLastGroupId(id)
        }

        const defaultGroup = groups.find(group => group.name.toUpperCase() === 'DANFOSS DEFAULT GROUP')
        const defaultGroupId = defaultGroup ? defaultGroup.id : null

        return {
          ...oldGroups,
          userPermissions,
          selectedGroup: { id, name },
          userGroups: groups,
          defaultGroupId
        }
      }
      break
    case aT.GROUP_CHANGE:
      const groupId = action.payload.groupId
      let newId = ''
      let newName = ''

      if (groupId === 'default') {
        const defaultGroup = userGroups.find(group => group.name.toUpperCase() === 'DANFOSS DEFAULT GROUP')
        newId = defaultGroup.id
        newName = defaultGroup.name
      } else {
        const userGroup = userGroups.find(group => group.id === groupId)
        newId = userGroup.id
        newName = userGroup.name
        const indexOfNew = userGroups.findIndex(group => group.name === newName && group.id === newId)
        if (indexOfNew === -1) {
          const sessionGroup = userGroups.find(group => group.id === getSessionGroupId())
          if (typeof sessionGroup === 'undefined') {
            const lastUsedGroup = userGroups.find(group => group.id === getLastGroupId())
            if (typeof lastUsedGroup === 'undefined') {
              newId = userGroups[0].id
              newName = userGroups[0].name
            } else {
              newId = lastUsedGroup.id
              newName = lastUsedGroup.name
            }
          } else {
            newId = sessionGroup.id
            newName = sessionGroup.name
          }
        }
      }
      cacheSessionGroupId(newId)
      cacheLastGroupId(newId)
      return {
        ...oldGroups,
        userPermissions,
        selectedGroup: { id: newId, name: newName },
        userGroups
      }

    case ha.types.GROUP_USER_DETAILS:
      return { ...state, loadingGroup: true }

    case ha.types.success(ha.types.GROUP_USER_DETAILS):
      const permissionsData = action.payload.data.data.result
      const permissionObjects = permissionsData.UserGroups.find(userGroup => userGroup.GroupId === selectedGroup.id)
      if (permissionObjects) {
        const userGroupPermissions = permissionObjects.user_permissions.map(permission =>
          permission.name.split('.').join('_').toUpperCase()
        )
        const reducedPermissions = userGroupPermissions.reduce((acc, cur) => ({ ...acc, [cur]: true }), {})
        return { ...state, userPermissions: reducedPermissions, loadingGroup: false }
      }
      break

    case ha.types.fail(ha.types.GROUP_USER_DETAILS):
      return { ...state, loadingGroup: false }

    case ha.types.success(ha.types.GROUP_USERS_DETAIL):
      const responseUsersDetail = action.payload.data.users ? action.payload.data.users : action.payload.data
      return { ...state, responseUsersDetail }
    case ha.types.fail(ha.types.GROUP_USERS_DETAIL):
      return { ...state }

    default:
  }

  return state
}

export default reducer

const getSelector = state => {
  const groupsState = state[c.NAME]
  const { defaultGroupId, userPermissions, selectedGroup, userGroups, ...actualGroups } = groupsState
  return actualGroups
}
const getInternalSelector = state => state[c.NAME]
const getGroup = (state, groupId) => getInternalSelector(state)[groupId]
const getDefaultGroupId = state => getInternalSelector(state).defaultGroupId || ''
const getSelectedGroup = state => getInternalSelector(state).selectedGroup
const getUserGroups = state => getInternalSelector(state).userGroups
const getCurrentGroup = (state, groupId) => getUserGroups(state).find(group => group.id === groupId) || {}
const getChildGroups = (state, groupId) => {
  const groups = getUserGroups(state)
  const groupIds = groups.map(group => group.id)
  const { id } = groups.find(group => group.id === groupId)
  let childGroups = []
  if (id === getDefaultGroupId(state)) {
    childGroups = groups.filter(group => !groupIds.includes(group.parent_id) && group.id !== groupId)
  } else {
    childGroups = groups.filter(group => group.parent_id === groupId)
  }
  return childGroups
}

const groupsLoaded = state => getInternalSelector(state).userGroups.length > 0

const getUserPermissions = state => getInternalSelector(state).userPermissions
const hasPermission = (state, permissionName) => {
  const permissions = getInternalSelector(state).userPermissions || {}
  return permissionName in permissions && permissions[permissionName]
}
const hasSomePermission = (state, permissionNames = []) => permissionNames.some(pName => hasPermission(state, pName))

const getLoadingGroup = state => getInternalSelector(state).loadingGroup

export {
  getSelector,
  getGroup,
  getDefaultGroupId,
  getSelectedGroup,
  getUserGroups,
  getCurrentGroup,
  getChildGroups,
  groupsLoaded,
  getUserPermissions,
  hasPermission,
  hasSomePermission,
  getLoadingGroup
}
