import { defineMessages } from 'react-intl'

const namespace = 'ChangeGroup'

export default defineMessages({
  changeGroup: {
    id: `${namespace}.changeGroup`,
    defaultMessage: 'Change Group'
  },
  filterByName: {
    id: `${namespace}.filterByName`,
    defaultMessage: 'Filter by name'
  },
  loadingGroup: {
    id: `${namespace}.loadingGroup`,
    defaultMessage: 'Loading group...'
  }
})
