// Used here:
//  https://css-tricks.com/debouncing-throttling-explained-examples/
export const WEBSOCKET_MAXIMUM_WAIT = 200
export const CHART_RENDER_MAXIMUM_WAIT = 100
export const REAL_TIME_CHART_UPDATE_FRECUENCY = 500 // In ms

//--- Configuration for MQTT.js client ---//
// AWS sets the following limits that the MQTT client should consider:
// http://docs.aws.amazon.com/general/latest/gr/aws_service_limits.html#limits_iot
// Options and their default values: https://github.com/mqttjs/MQTT.js#client

export const MQTT_CONFIG = {
  // If the connect has not succeeded within this number of seconds,
  // it is deemed to have failed.
  // The default is 30 * 1000 milliseconds.
  connectTimeout: 3 * 1000,
  // The server disconnects this client if there is no activity for
  // this number of seconds.
  // The default value of 60 seconds is assumed if not set.
  // "AWS IoT supports keep-alive values between 5 seconds and 20 minutes"
  keepalive: 20 * 60, // Maximum time: 20 minutes
  // Interval between two reconnections. Default: 1000 milliseconds.
  reconnectPeriod: 5000
}

//--------//

export const DEFAULT_GROUP_NAME = 'Danfoss Default Group'
export const DEFAULT_GROUP_ID = process.env.REACT_APP_HTTP_PLUS1CONNECT_API.includes(
  'https://api.stg.plus1connect.danfoss.com'
)
  ? '3c578a47-5fca-417f-9900-c53aad6329be' //STAGING
  : process.env.REACT_APP_HTTP_PLUS1CONNECT_API.includes('https://api.sbx.plus1connect.danfoss.com')
    ? '7c333a79-2fc9-460a-b508-77b06dbd730b' // SANDBOX
    : '6e1fe3b5-56a4-4ee3-bbc5-63863f16c9cb' // PRODUCTION

//Pune API permissions

export const USER_CREATE = 'USER_CREATE'
export const USER_EDIT = 'USER_EDIT'
export const USER_READ = 'USER_READ'
export const DEVICE_EDIT = 'DEVICE_EDIT'
export const DEVICE_READ = 'DEVICE_READ'
export const DEVICE_CREATE = 'DEVICE_CREATE'
export const DEVICE_DELETE = 'DEVICE_DELETE'
export const DEVICECONFIGURATION_EDIT = 'DEVICECONFIGURATION_EDIT'
export const DEVICECONFIGURATION_READ = 'DEVICECONFIGURATION_READ'
export const DEVICECONFIGURATION_CREATE = 'DEVICECONFIGURATION_CREATE'
export const DEVICECONFIGURATION_DELETE = 'DEVICECONFIGURATION_DELETE'
export const DEVICESIM_EDIT = 'DEVICESIM_EDIT'
export const DEVICESIM_READ = 'DEVICESIM_READ'
export const DEVICESIM_CREATE = 'DEVICESIM_CREATE'
export const DEVICESIM_DELETE = 'DEVICESIM_DELETE'
export const ROLE_EDIT = 'ROLE_EDIT'
export const ROLE_READ = 'ROLE_READ'
export const PERMISSION_EDIT = 'PERMISSION_EDIT'
export const PERMISSION_READ = 'PERMISSION_READ'
export const FLEET_EDIT = 'FLEET_EDIT'
export const FLEET_READ = 'FLEET_READ'
export const FLEET_CREATE = 'FLEET_CREATE'
export const FLEET_DELETE = 'FLEET_DELETE'
export const GEOFENCE_EDIT = 'GEOFENCE_EDIT'
export const GEOFENCE_READ = 'GEOFENCE_READ'
export const GEOFENCE_CREATE = 'GEOFENCE_CREATE'
export const GEOFENCE_DELETE = 'GEOFENCE_DELETE'
export const RULE_READ = 'RULE_READ'
export const RULE_CREATE = 'RULE_CREATE'
export const RULE_EDIT = 'RULE_EDIT'
export const RULE_DELETE = 'RULE_DELETE'
export const SCHEDULE_CREATE = 'SCHEDULE_CREATE'
export const SCHEDULE_READ = 'SCHEDULE_READ'
export const SCHEDULE_EDIT = 'SCHEDULE_EDIT'
export const SCHEDULE_DELETE = 'SCHEDULE_DELETE'
export const FIRMWARE_EDIT = 'FIRMWARE_EDIT'
export const FIRMWARE_READ = 'FIRMWARE_READ'
export const FIRMWARE_CREATE = 'FIRMWARE_CREATE'
export const FIRMWARE_DELETE = 'FIRMWARE_DELETE'
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE'
export const CUSTOMER_READ = 'CUSTOMER_READ'
export const CUSTOMER_EDIT = 'CUSTOMER_EDIT'
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE'
export const GEOTRACKING_EDIT = 'GEOTRACKING_EDIT'
export const GEOTRACKING_VIEW = 'GEOTRACKING_VIEW'
export const CHARTS_EDIT = 'CHARTS_EDIT'
export const CHARTS_READ = 'CHARTS_READ'
export const CHARTS_CREATE = 'CHARTS_CREATE'
export const CHARTS_DELETE = 'CHARTS_DELETE'
export const REPORTS_EDIT = 'REPORTS_EDIT'
export const REPORTS_READ = 'REPORTS_READ'
export const REPORTS_CREATE = 'REPORTS_CREATE'
export const REPORTS_DELETE = 'REPORTS_DELETE'
export const EVENTSHISTORY_EDIT = 'EVENTSHISTORY_EDIT'
export const EVENTSHISTORY_VIEW = 'EVENTSHISTORY_VIEW'
export const NODEDETAILS_EDIT = 'NODEDETAILS_EDIT'
export const NODEDETAILS_VIEW = 'NODEDETAILS_VIEW'
export const DASHBOARDS_EDIT = 'DASHBOARDS_EDIT'
export const DASHBOARDS_VIEW = 'DASHBOARDS_VIEW'
export const GROUPS_EDIT = 'GROUPS_EDIT'
export const GROUPS_READ = 'GROUPS_READ'
export const GROUPS_CREATE = 'GROUPS_CREATE'
export const GROUPS_DELETE = 'GROUPS_DELETE'

//News
export const MAP_READ = 'MAP_READ'

export const NOTIFICATION_EDIT = 'NOTIFICATION_EDIT'
export const NOTIFICATION_READ = 'NOTIFICATION_READ'
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE'
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE'

export const GROUPMONITOR_READ = 'GROUPMONITOR_READ'

export const GROUPDASHBOARD_EDIT = 'GROUPDASHBOARD_EDIT'
export const GROUPDASHBOARD_READ = 'GROUPDASHBOARD_READ'
export const GROUPDASHBOARD_CREATE = 'GROUPDASHBOARD_CREATE'
export const GROUPDASHBOARD_DELETE = 'GROUPDASHBOARD_DELETE'

export const DEVICEDASHBOARD_EDIT = 'DEVICEDASHBOARD_EDIT'
export const DEVICEDASHBOARD_READ = 'DEVICEDASHBOARD_READ'
export const DEVICEDASHBOARD_CREATE = 'DEVICEDASHBOARD_CREATE'
export const DEVICEDASHBOARD_DELETE = 'DEVICEDASHBOARD_DELETE'

export const GROUPROLE_EDIT = 'GROUPROLE_EDIT'
export const GROUPROLE_READ = 'GROUPROLE_READ'
export const GROUPROLE_CREATE = 'GROUPROLE_CREATE'
export const GROUPROLE_DELETE = 'GROUPROLE_DELETE'

export const RESOURCESHARING_ALLOW = 'RESOURCESHARING_ALLOW'

export const RESOURCE_EDIT = 'RESOURCE_EDIT'
export const RESOURCE_READ = 'RESOURCE_READ'
export const RESOURCE_CREATE = 'RESOURCE_CREATE'
export const RESOURCE_DELETE = 'RESOURCE_DELETE'

export const DATAPLAN_READ = 'DATAPLAN_READ'
export const DATAPLAN_SHARE = 'DATAPLAN_SHARE'
export const DATAPLAN_APPLY = 'DATAPLAN_APPLY'

export const ACCESSPASS_SHARE = 'ACCESSPASS_SHARE'
export const ACCESSPASS_CONSUME = 'ACCESSPASS_CONSUME'

//Group actions
export const UPDATE_FIRMWARE_CS = 'UPDATE_FIRMWARE_CS'
export const WRITE_CONFIGURATION_CS = 'WRITE_CONFIGURATION_CS'
export const IMPORT_DASHBOARDS = 'IMPORT_DASHBOARDS'
export const DELETE_DASHBOARDS = 'DELETE_DASHBOARDS'
export const IMPORT_ADVANCED_SIGNALS = 'IMPORT_ADVANCED_SIGNALS'

export const LAST_OPEN_URL = 'lastOpenURL'

export const MAX_DECIMALS = 5
