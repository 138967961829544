import PropTypes from 'prop-types'
import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import Tooltip from '@material-ui/core/Tooltip'

import DropdownMenu from 'components/DropdownMenu'
import { getSmsServiceConsentUrl } from 'modules/smsServiceConsent/urls'

import { useAuth0 } from '../../../../react-auth0-spa'
import messages from './messages'
import ChangeGroupDialog from './ChangeGroupDialog'

import './scrollbar.css'

const styles = {
  divider: {
    margin: '11px 0px'
  },
  menuHeader: {
    color: '#5E5D52',
    display: 'flex',
    padding: '11px 16px 6px',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: '#5E5D52',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  menuItemIcon: {
    marginRight: 16
  },
  tooltip: {
    backgroundColor: '#212121'
  },
  arrow: {
    color: '#212121'
  }
}

const ProfileDropdown = ({
  classes,
  user,
  unauthorize,
  groups,
  selectedGroup,
  changeGroup,
  loadPermissionsOfUserInGroup,
  disabled,
  intl,
  fontColor,
  color,
  ...other
}) => {
  const { formatMessage } = intl
  const [openDialog, setOpenDialog] = useState(false)

  const { logout } = useAuth0()

  if (!user) {
    return <span>{formatMessage(messages.loadingInfo)}</span>
  }

  const profilePicture = (
    <Avatar
      style={{
        backgroundColor: fontColor,
        color,
        fontWeight: 'bold',
        marginLeft: '5px',
        height: 28,
        width: 28,
        fontSize: 14
      }}
    >
      {user.firstName.substring(0, 1)}
    </Avatar>
  )

  const label = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>{profilePicture}</div>
      <span className='hidden-xs' style={{ textAlign: 'start' }}>
        <span
          className='text-capitalize profile-name'
          style={{ marginLeft: '5px', paddingRight: '5px', color: fontColor }}
        >
          {user.firstName} {user.lastName + '\n'}
        </span>
        <div
          style={{
            color: fontColor,
            fontWeight: 'normal',
            fontSize: 12,
            textTransform: 'none',
            marginLeft: '5px',
            paddingLeft: '5px'
          }}
        >
          {selectedGroup.name}
        </div>
      </span>
    </div>
  )

  const handleLogout = () => {
    //Logout from SSO
    try {
      unauthorize()
      logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
    } catch (error) {
      unauthorize()
    }
  }

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    setOpenDialog(false)
  }

  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50
      }}
    >
      <DropdownMenu icon={label} {...other} name='user_button'>
        <a href={process.env.REACT_APP_DIP_REDIRECT_EDIT_PROFILE_URI} rel='noopener noreferrer' target='_blank'>
          <MenuItem classes={{ root: classes.menuItem }} name='my.danfoss-DIP-profile'>
            <AccountCircleOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.myProfile)}
          </MenuItem>
        </a>
        {disabled ? (
          <Tooltip
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            placement='bottom'
            title='It is not allowed to change group on this page'
          >
            <span>
              <MenuItem name='change-group' disabled>
                <AccountTreeOutlinedIcon classes={{ root: classes.menuItemIcon }} />
                {formatMessage(messages.changeGroup)}
              </MenuItem>
            </span>
          </Tooltip>
        ) : (
          <MenuItem classes={{ root: classes.menuItem }} name='change-group' onClick={handleDialogOpen}>
            <AccountTreeOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.changeGroup)}
          </MenuItem>
        )}
        <Link style={{ textDecoration: 'none' }} to={getSmsServiceConsentUrl()}>
          <MenuItem classes={{ root: classes.menuItem }} name='sms-service-consent'>
            <AssignmentOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.smsServiceConsent)}
          </MenuItem>
        </Link>
        <Divider classes={{ root: classes.divider }} />
        <a href={process.env.REACT_APP_MARKETPLACE_LINK} rel='noopener noreferrer' target='_blank'>
          <MenuItem classes={{ root: classes.menuItem }} name='Market-Place'>
            <ShoppingCartOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.marketPlace)}
          </MenuItem>
        </a>
        <MenuItem classes={{ root: classes.menuItem }} name='logout_button' onClick={handleLogout}>
          <Icon className='zmdi zmdi-sign-in' classes={{ root: classes.menuItemIcon }} />
          {formatMessage(messages.logout)}
        </MenuItem>
      </DropdownMenu>
      <ChangeGroupDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        groups={groups}
        selectedGroup={selectedGroup}
        changeGroup={changeGroup}
        loadPermissionsOfUserInGroup={loadPermissionsOfUserInGroup}
        classes={classes}
        intl={intl}
        disabled={disabled}
      />
    </div>
  )
}

ProfileDropdown.propTypes = {
  changeGroup: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  fontColor: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  loadPermissionsOfUserInGroup: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  unauthorize: PropTypes.func.isRequired,
  user: PropTypes.object
}

ProfileDropdown.defaultProps = {
  user: null
}

export default withStyles(styles)(injectIntl(ProfileDropdown))
