import * as t from './actionTypes'
import { createRequestAction } from './utils'
import * as plus1Url from '../plus1ConnectUrl'
import * as url from '../url'

const getGroupHierarchy = () => createRequestAction(t.GROUP_HIERARCHY, url.groupsHierarchy(), 'get')

const getGroupHierarchyWithNotifications = () =>
  createRequestAction(t.GROUP_HIERARCHY, url.groupsHierarchyWithNotifications(), 'get')

const getGroups = (limit, offset) =>
  createRequestAction(t.GROUPS, plus1Url.groups(limit, offset), 'get', null, 'plus1Connect')

const getUsersOfGroup = groupName =>
  createRequestAction(t.GROUP_USERS, plus1Url.usersOfGroup(groupName), 'get', null, 'plus1Connect')

const getPermissionsOfUserInGroup = groupId =>
  createRequestAction(t.GROUP_USER_PERMISSIONS, plus1Url.permissionsOfUserInGroup(groupId), 'get', null, 'plus1Connect')

const getGroupUsersDetails = (groupId, emails) =>
  createRequestAction(t.GROUP_USERS_DETAIL, plus1Url.usersOfGroupDetails(groupId), 'post', { emails }, 'plus1Connect')

const createGroup = (parentGroupId, groupName) =>
  createRequestAction(t.GROUP_CREATE, plus1Url.createGroup(parentGroupId), 'post', { groupName }, 'plus1Connect')

const updateGroup = (groupId, groupName) =>
  createRequestAction(t.GROUP_UPDATE, plus1Url.updateGroup(), 'put', { groupId, groupName }, 'plus1Connect')

const deleteGroup = groupId =>
  createRequestAction(t.GROUP_DELETE, plus1Url.deleteGroup(), 'put', { groupId }, 'plus1Connect')

const addUsersToGroup = (groupId, users, permissions) =>
  createRequestAction(
    t.GROUP_ADD_USERS,
    plus1Url.addUsersToGroup(groupId),
    'put',
    { users, ...(Array.isArray(permissions) && { permissions }) },
    'plus1Connect'
  )

const removeUsersFromGroup = (groupId, users) =>
  createRequestAction(t.GROUP_REMOVE_USERS, plus1Url.removeUsersFromGroup(groupId), 'put', { users }, 'plus1Connect')

const addDevicesToGroup = (groupId, deviceIds, deviceType, originGroupId) =>
  createRequestAction(
    t.GROUP_ADD_DEVICES,
    plus1Url.addDevicesToGroup(groupId),
    'put',
    { resources: deviceIds, resourceType: deviceType, originGroupId },
    'plus1Connect'
  )

const removeDevicesFromGroup = (groupId, deviceIds, deviceType) =>
  createRequestAction(
    t.GROUP_ADD_DEVICES,
    plus1Url.removeDevicesFromGroup(groupId),
    'put',
    { resources: deviceIds, resourceType: deviceType },
    'plus1Connect'
  )

const getUserDetails = (/*groupId,*/ limit, offset, firstName = '', lastName = '') =>
  createRequestAction(
    t.GROUP_USER_DETAILS,
    plus1Url.userDetails(/*groupId,*/ limit, offset),
    'get',
    { firstName, lastName },
    'plus1Connect'
  )

const getAllRolesOfGroup = groupId =>
  createRequestAction(t.GROUP_ALL_ROLES, plus1Url.roles(groupId), 'get', null, 'plus1Connect')

const createRole = (groupId, roleName, permissions) =>
  createRequestAction(t.GROUP_CREATE_ROLE, plus1Url.roles(groupId), 'post', { roleName, permissions }, 'plus1Connect')

const getDetailsOfRole = (groupId, roleId) =>
  createRequestAction(t.GROUP_ROLE_DETAILS, plus1Url.role(groupId, roleId), 'get', null, 'plus1Connect')

const updateRole = (groupId, groupRoleId, roleName, permissions) =>
  createRequestAction(
    t.GROUP_UPDATE_ROLE,
    plus1Url.roles(groupId),
    'put',
    { ...(roleName !== '' && { roleName }), permissions, groupRoleId },
    'plus1Connect'
  )

const assignRoleToUser = (groupId, groupRoleId, users) =>
  createRequestAction(
    t.GROUP_ASSIGN_ROLE,
    plus1Url.assignRoleToUser(groupId),
    'put',
    { users, groupRoleId },
    'plus1Connect'
  )

const getDetailedUsersOfGroup = (groupId, limit, offset) =>
  createRequestAction(
    t.GROUP_DETAILED_USERS,
    plus1Url.detailedUsersOfGroup(groupId, limit, offset),
    'get',
    null,
    'plus1Connect'
  )

const deleteRole = (groupId, roleId) =>
  createRequestAction(t.GROUP_DELETE_ROLE, plus1Url.deleteRole(groupId, roleId), 'delete', null, 'plus1Connect')

const addUsersToGroupWithRole = (groupId, groupRoleId, users) =>
  createRequestAction(
    t.GROUP_ADD_USER_ROLE,
    plus1Url.addUsersToGroupWithRole(groupId),
    'put',
    { users, groupRoleId },
    'plus1Connect'
  )

export {
  getGroupHierarchy,
  getGroupHierarchyWithNotifications,
  getGroups,
  getUsersOfGroup,
  getGroupUsersDetails,
  getPermissionsOfUserInGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  addUsersToGroup,
  removeUsersFromGroup,
  addDevicesToGroup,
  removeDevicesFromGroup,
  getUserDetails,
  getAllRolesOfGroup,
  createRole,
  getDetailsOfRole,
  updateRole,
  assignRoleToUser,
  getDetailedUsersOfGroup,
  deleteRole,
  addUsersToGroupWithRole
}
