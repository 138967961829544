import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'MFIO.error',
    description: 'Error icon tooltip',
    defaultMessage: 'Error'
  },
  deleteSignal: {
    id: 'MFIO.deleteSignal',
    description: 'Delete signal icon tooltip',
    defaultMessage: 'Delete signal'
  },
  open: {
    id: 'MFIO.open',
    description: 'Open icon tooltip',
    defaultMessage: 'Open'
  },
  close: {
    id: 'MFIO.close',
    description: 'Close icon tooltip',
    defaultMessage: 'Close'
  },
  pinFunction: {
    id: 'MFIO.pinFunction',
    description: 'Pin function',
    defaultMessage: 'Pin function'
  },
  name: {
    id: 'MFIO.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  logCycle: {
    id: 'MFIO.logCycle',
    description: 'Log cycle',
    defaultMessage: 'Time interval (s)'
  },
  inputSignal: {
    id: 'MFIO.inputSignal',
    description: 'Input signal',
    defaultMessage: 'Input signal'
  },
  inputRange: {
    id: 'MFIO.inputRange',
    description: 'Input range',
    defaultMessage: 'Input range'
  },
  bias: {
    id: 'MFIO.bias',
    description: 'Bias',
    defaultMessage: 'Bias'
  },
  digThreshHigh: {
    id: 'MFIO.digThreshHigh',
    description: 'Digital threshold high',
    defaultMessage: 'Digital threshold high (mV)'
  },
  digThreshLow: {
    id: 'MFIO.digThreshLow',
    description: 'Digital threshold low',
    defaultMessage: 'Digital threshold low (mV)'
  },
  unit: {
    id: 'MFIO.unit',
    description: 'Unit',
    defaultMessage: 'Unit'
  },
  offset: {
    id: 'MFIO.offset',
    description: 'Offset',
    defaultMessage: 'Offset'
  },
  multiplier: {
    id: 'MFIO.multiplier',
    description: 'Multiplier',
    defaultMessage: 'Multiplier'
  },
  decimals: {
    id: 'MFIO.decimals',
    description: 'Decimals',
    defaultMessage: 'Decimals'
  },
  defaultPowerUp: {
    id: 'MFIO.defaultPowerUp',
    description: 'Default power up',
    defaultMessage: 'Default power up'
  },
  powerManagementEnable: {
    id: 'MFIO.powerManagementEnable',
    description: 'Power management checkbox label',
    defaultMessage: 'Enable power management'
  },
  cycleTimeDelay: {
    id: 'MFIO.cycleTimeDelay',
    description: 'Cycle time delay label',
    defaultMessage: 'Cycle time delay (s)'
  },
  shutDownDelay: {
    id: 'MFIO.shutDownDelay',
    description: 'Shutdown delay label',
    defaultMessage: 'Shutdown delay'
  },
  typeOfValue: {
    id: 'MFIO.typeOfValue',
    description: 'Type of value',
    defaultMessage: 'Type of value'
  },
  lastValue: {
    id: 'MFIO.lastValue',
    description: 'Last value',
    defaultMessage: 'Last value'
  },
  averageValue: {
    id: 'MFIO.averageValue',
    description: 'Average value',
    defaultMessage: 'Average value'
  },
  minValue: {
    id: 'MFIO.minValue',
    description: 'Minimum value',
    defaultMessage: 'Minimum value'
  },
  maxValue: {
    id: 'MFIO.maxValue',
    description: 'Maximum value',
    defaultMessage: 'Maximum value'
  },
  automaticShutdownTooltipInfo: {
    id: 'MFIO.automaticShutdownTooltipInfo',
    description: 'Automatic shutdown information tooltip text',
    defaultMessage: 'When "Automatic shutdown" is on, the device will automatically turn off after the specified delay'
  },
  cyclicWakeUpTooltipInfo: {
    id: 'MFIO.cyclicWakeUpTooltipInfo',
    description: 'Cyclic wake-up information tooltip text',
    defaultMessage:
      'When "Cyclic wake-up" is on and the device is turned off, it will ' +
      'wake itself up based on the specified frequency'
  },
  automaticShutdown: {
    id: 'MFIO.automaticShutdown',
    description: 'Automatic Shutdown',
    defaultMessage: 'Automatic shutdown'
  },
  cyclicWakeUp: {
    id: 'MFIO.cyclicWakeUp',
    description: 'Cyclic Wake-Up',
    defaultMessage: 'Cyclic wake-Up'
  },
  wakeUpFrequency: {
    id: 'MFIO.wakeUpFrequency',
    description: 'Wake-up frequency',
    defaultMessage: 'Wake-up frequency'
  },
  yes: {
    id: 'MFIO.yes',
    description: 'Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'MFIO.no',
    description: 'No',
    defaultMessage: 'No'
  },
  clamp15IgnitionSignal: {
    id: 'MFIO.clamp15IgnitionSignal',
    description: 'CLAMP 15 (Ignition signal)',
    defaultMessage: 'CLAMP 15 (Ignition signal)'
  }
})

export default messages
