import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ReactComponent as InfoIcon } from 'icons/info-big.svg'

import messages from './messages'
import { optionsForMFIOItem } from '../config'
import { MFIO_FIELDS } from '../constants'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  button: {
    margin: 0
  },
  actionsRoot: {
    paddingRight: 1
  },
  contentRoot: {
    padding: 16
  },
  errorButton: {
    color: 'red'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {},
  clampTooltip: {
    backgroundColor: '#212121',
    width: 200
  },
  clampTooltipArrow: {
    color: '#212121'
  },
  clampSwitchLabelRoot: {
    marginRight: 8
  },
  clampSwitchLabel: {
    fontSize: 16
  }
})

const MFIO = ({
  averageValue,
  bias,
  biasErrorMessage,
  changeLocalConfigurationField,
  classes,
  cycleTimeDelay,
  cycleTimeDelayErrorMessage,
  dataLoggingEnable,
  decimals,
  decimalsErrorMessage,
  defaultPowerUp,
  defaultPowerUpErrorMessage,
  digThreshHigh,
  digThreshHighErrorMessage,
  digThreshLow,
  digThreshLowErrorMessage,
  expanded,
  handleMFIODelete,
  handleMFIOFieldChange,
  inputRange,
  inputRangeErrorMessage,
  inputSignal,
  inputSignalErrorMessage,
  intl,
  isEditable,
  lastValue,
  logCycle,
  logCycleErrorMessage,
  maxValue,
  minValue,
  multiplier,
  multiplierErrorMessage,
  name,
  nameErrorMessage,
  noErrorInSignal,
  offset,
  offsetErrorMessage,
  pinFunction,
  pinFunctionErrorMessage,
  powerManagementEnable,
  shutdownDelay,
  shutdownDelayErrorMessage,
  signalId,
  unit,
  unitErrorMessage
}) => {
  useEffect(() => {
    if (pinFunction === 3)
      handleMFIOFieldChange(signalId, MFIO_FIELDS.name, formatMessage(messages.clamp15IgnitionSignal))
    else handleMFIOFieldChange(signalId, MFIO_FIELDS.name, '')
  }, [pinFunction])

  const returnFields = () => {
    switch (pinFunction) {
      case 0:
        return []
      case 1: // DIGITAL OUTPUT MODE
        return (
          <Grid container spacing={2}>
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={nameErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.name)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.name}
                    inputProps={{ maxLength: 50 }}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.name, event.target.value)
                    }}
                    value={name}
                  />
                  <FormHelperText>{nameErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.name)}</Typography>
                <label>{name}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={defaultPowerUpErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.defaultPowerUp)}</InputLabel>
                  <Select
                    name={MFIO_FIELDS.defaultPowerUp}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.defaultPowerUp, event.target.value)
                    }}
                    value={defaultPowerUp}
                  >
                    {optionsForMFIOItemSelects.defaultPowerUp.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{defaultPowerUpErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.defaultPowerUp)}</Typography>
                <label>{defaultPowerUp.toString() === 'true' ? 'High' : 'Low'}</label>
              </Grid>
            )}
          </Grid>
        )
      case 2: //INPUT MODE
        return (
          <Grid container spacing={2}>
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={nameErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.name)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.name}
                    inputProps={{ maxLength: 50 }}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.name, event.target.value)
                    }}
                    value={name}
                  />
                  <FormHelperText>{nameErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.name)}</Typography>
                <label>{name}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={inputSignalErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.inputSignal)}</InputLabel>
                  <Select
                    disabled={pinFunction === 1 ? true : false}
                    name={MFIO_FIELDS.inputSignal}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.inputSignal, event.target.value)
                    }}
                    value={inputSignal}
                  >
                    {optionsForMFIOItemSelects.inputSignal.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{inputSignalErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.inputSignal)}</Typography>
                <label>{inputSignal}</label>
              </Grid>
            )}
            {inputSignal !== 'Current' &&
              inputSignal !== 'Resistance' &&
              (isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={inputRangeErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.inputRange)}</InputLabel>
                    <Select
                      name={MFIO_FIELDS.inputRange}
                      onChange={event => {
                        handleMFIOFieldChange(signalId, MFIO_FIELDS.inputRange, event.target.value)
                      }}
                      value={inputRange}
                    >
                      {optionsForMFIOItemSelects.inputRange.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{inputRangeErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.inputRange)}</Typography>
                  <label>{optionsForMFIOItemSelects.inputRange[inputRange].label}</label>
                </Grid>
              ))}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={logCycleErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.logCycle)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.logCycle}
                    inputProps={{ min: 10, max: 65535 }}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.logCycle, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(s)'
                    type='number'
                    value={logCycle}
                  />
                  <FormHelperText>{logCycleErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.logCycle)}</Typography>
                <label>{logCycle}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={offsetErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.offset)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.offset}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.offset, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={offset}
                  />
                  <FormHelperText>{offsetErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.offset)}</Typography>
                <label>{offset}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={multiplierErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.multiplier)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.multiplier}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.multiplier, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={multiplier}
                  />
                  <FormHelperText>{multiplierErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.multiplier)}</Typography>
                <label>{multiplier}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={decimalsErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.decimals)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.decimals}
                    inputProps={{ min: 0 }}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.decimals, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={decimals}
                  />
                  <FormHelperText>{decimalsErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.decimals)}</Typography>
                <label>{decimals}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={unitErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.unit)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS.unit}
                    inputProps={{ maxLength: 50 }}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.unit, event.target.value)
                    }}
                    value={unit}
                  />
                  <FormHelperText>{unitErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.unit)}</Typography>
                <label>{unit}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={biasErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.bias)}</InputLabel>
                  <Select
                    disabled={inputSignal === 'Current' || inputSignal === 'Resistance' ? true : false}
                    name={MFIO_FIELDS.bias}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.bias, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    type='number'
                    value={bias}
                  >
                    {optionsForMFIOItemSelects.bias.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{biasErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.bias)}</Typography>
                <label>{optionsForMFIOItemSelects.bias[bias].label}</label>
              </Grid>
            )}
            {inputSignal === 'Digital Input' &&
              (isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={digThreshLowErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.digThreshLow)}</InputLabel>
                    <Input
                      id={MFIO_FIELDS.digThreshLow}
                      inputProps={{ min: 0, max: 65535 }}
                      onChange={event => {
                        handleMFIOFieldChange(signalId, MFIO_FIELDS.digThreshLow, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='(mV)'
                      type='number'
                      value={digThreshLow}
                    />
                    <FormHelperText>{digThreshLowErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.digThreshLow)}</Typography>
                  <label>{digThreshLow}</label>
                </Grid>
              ))}
            {inputSignal === 'Digital Input' &&
              (isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={digThreshHighErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.digThreshHigh)}</InputLabel>
                    <Input
                      id={MFIO_FIELDS.digThreshHigh}
                      inputProps={{ min: 0, max: 65535 }}
                      onChange={event => {
                        handleMFIOFieldChange(signalId, MFIO_FIELDS.digThreshHigh, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='(mV)'
                      type='number'
                      value={digThreshHigh}
                    />
                    <FormHelperText>{digThreshHighErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.digThreshHigh)}</Typography>
                  <label>{digThreshHigh}</label>
                </Grid>
              ))}
          </Grid>
        )
      case 3:
        return (
          <Grid item md={9} style={{ padding: 8, marginTop: 14 }}>
            <Grid container spacing={2}>
              <Grid container item lg={4} sm={6} xs={12}>
                {isEditable ? (
                  <Grid item>
                    <FormControlLabel
                      classes={{ root: classes.clampSwitchLabelRoot, label: classes.clampSwitchLabel }}
                      control={
                        <Switch
                          checked={powerManagementEnable === 1 || powerManagementEnable === 3}
                          onChange={event => {
                            changeLocalConfigurationField(
                              'powerManagementEnable',
                              event.target.checked ? powerManagementEnable + 1 : powerManagementEnable - 1
                            )
                          }}
                        />
                      }
                      label={formatMessage(messages.automaticShutdown)}
                    />
                    <Tooltip
                      arrow
                      classes={{ tooltip: classes.clampTooltip, arrow: classes.clampTooltipArrow }}
                      placement='top'
                      title={formatMessage(messages.automaticShutdownTooltipInfo)}
                    >
                      <InfoIcon style={{ marginTop: 5 }} />
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography style={{ fontSize: 16 }}>{formatMessage(messages.automaticShutdown)}</Typography>
                    <label>
                      {powerManagementEnable === 2 || powerManagementEnable === 3
                        ? formatMessage(messages.yes)
                        : formatMessage(messages.no)}
                    </label>
                  </Grid>
                )}
                <Grid item style={{ marginTop: 20 }} xs={12}>
                  {isEditable ? (
                    <TextField
                      InputProps={{ endAdornment: 'minutes' }}
                      disabled={powerManagementEnable === 0 || powerManagementEnable === 2}
                      error={shutdownDelayErrorMessage !== ''}
                      fullWidth
                      helperText={shutdownDelayErrorMessage}
                      inputProps={{ min: 2, max: 60 }}
                      label={formatMessage(messages.shutDownDelay)}
                      onChange={event => {
                        changeLocalConfigurationField('shutdownDelay', event.target.value)
                      }}
                      type='number'
                      value={Math.round(shutdownDelay / 60)}
                    />
                  ) : (
                    <React.Fragment>
                      <Typography>{formatMessage(messages.shutDownDelay)}</Typography>
                      <label>{`${Math.round(shutdownDelay / 60)} mins`}</label>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid container item lg={4} sm={6} xs={12}>
                {isEditable ? (
                  <Grid item>
                    <FormControlLabel
                      classes={{ root: classes.clampSwitchLabelRoot, label: classes.clampSwitchLabel }}
                      control={
                        <Switch
                          checked={powerManagementEnable === 2 || powerManagementEnable === 3}
                          onChange={event => {
                            changeLocalConfigurationField(
                              'powerManagementEnable',
                              event.target.checked ? powerManagementEnable + 2 : powerManagementEnable - 2
                            )
                          }}
                        />
                      }
                      label={formatMessage(messages.cyclicWakeUp)}
                    />
                    <Tooltip
                      arrow
                      classes={{ tooltip: classes.clampTooltip, arrow: classes.clampTooltipArrow }}
                      placement='top'
                      title={formatMessage(messages.cyclicWakeUpTooltipInfo)}
                    >
                      <InfoIcon style={{ marginTop: 5 }} />
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography style={{ fontSize: 16 }}>{formatMessage(messages.cyclicWakeUp)}</Typography>
                    <label>
                      {powerManagementEnable === 2 || powerManagementEnable === 3
                        ? formatMessage(messages.yes)
                        : formatMessage(messages.no)}
                    </label>
                  </Grid>
                )}
                <Grid item style={{ marginTop: 20 }} xs={12}>
                  {isEditable ? (
                    <TextField
                      InputProps={{ endAdornment: 'minutes' }}
                      disabled={powerManagementEnable === 0 || powerManagementEnable === 1}
                      error={cycleTimeDelayErrorMessage !== ''}
                      fullWidth
                      helperText={cycleTimeDelayErrorMessage}
                      inputProps={{ min: 10, max: 1440 }}
                      label={formatMessage(messages.wakeUpFrequency)}
                      onChange={event => {
                        changeLocalConfigurationField('cycleTimeDelay', event.target.value)
                      }}
                      type='number'
                      value={Math.round(cycleTimeDelay / 60)}
                    />
                  ) : (
                    <React.Fragment>
                      <Typography>{formatMessage(messages.wakeUpFrequency)}</Typography>
                      <label>{`${Math.round(cycleTimeDelay / 60)} mins`}</label>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      default:
        return []
    }
  }

  const returnValueTypes = () => {
    switch (pinFunction) {
      case 0: // No MFIO
      case 1: // DIGITAL OUTPUT MODE
      case 3: // CLAMP 15
        return []
      case 2: // //INPUT MODE
        return (
          <Grid item md={9} style={{ padding: 8 }}>
            <Grid container spacing={2}>
              {isEditable ? (
                <Grid item lg={12} md={12} style={{ padding: '0 8px 0 8px' }} xs={12}>
                  <Typography>{formatMessage(messages.typeOfValue)}</Typography>
                </Grid>
              ) : null}
              {isEditable ? (
                <Grid item lg={3} md={3} style={{ padding: '0 8px 8px 8px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lastValue === 0 ? false : true}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onChange={event => {
                          if (lastValue === 1 && averageValue === 0 && minValue === 0 && maxValue === 0) {
                            return
                          } else {
                            handleMFIOFieldChange(signalId, MFIO_FIELDS.lastValue, event.target.checked ? 1 : 0)
                          }
                        }}
                      />
                    }
                    label={formatMessage(messages.lastValue)}
                  />
                </Grid>
              ) : (
                <Grid item lg={2} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.lastValue)}</Typography>
                  {lastValue === 1 ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={3} style={{ padding: '0 8px 8px 8px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={averageValue === 0 ? false : true}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onChange={event => {
                          if (lastValue === 0 && averageValue === 1 && minValue === 0 && maxValue === 0) {
                            return
                          } else {
                            handleMFIOFieldChange(signalId, MFIO_FIELDS.averageValue, event.target.checked ? 1 : 0)
                          }
                        }}
                      />
                    }
                    label={formatMessage(messages.averageValue)}
                  />
                </Grid>
              ) : (
                <Grid item lg={2} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.averageValue)}</Typography>
                  {averageValue === 1 ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={3} style={{ padding: '0 8px 8px 8px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={minValue === 0 ? false : true}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onChange={event => {
                          if (lastValue === 0 && averageValue === 0 && minValue === 1 && maxValue === 0) {
                            return
                          } else {
                            handleMFIOFieldChange(signalId, MFIO_FIELDS.minValue, event.target.checked ? 1 : 0)
                          }
                        }}
                      />
                    }
                    label={formatMessage(messages.minValue)}
                  />
                </Grid>
              ) : (
                <Grid item lg={2} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.minValue)}</Typography>
                  {minValue === 1 ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={2} md={3} style={{ padding: '0 8px 8px 8px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={maxValue === 0 ? false : true}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onChange={event => {
                          if (lastValue === 0 && averageValue === 0 && minValue === 0 && maxValue === 1) {
                            return
                          } else {
                            handleMFIOFieldChange(signalId, MFIO_FIELDS.maxValue, event.target.checked ? 1 : 0)
                          }
                        }}
                      />
                    }
                    label={formatMessage(messages.maxValue)}
                  />
                </Grid>
              ) : (
                <Grid item lg={2} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.maxValue)}</Typography>
                  {maxValue === 1 ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      default:
        return []
    }
  }

  const { formatMessage } = intl
  const optionsForMFIOItemSelects = optionsForMFIOItem()

  return (
    <Card elevation={0} style={{ backgroundColor: dataLoggingEnable === false ? '#d3d3d3' : 'white', borderRadius: 0 }}>
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            {!noErrorInSignal && isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.error)}>
                <IconButton className={classes.errorButton}>
                  <ErrorIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.deleteSignal)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleMFIODelete(signalId)
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.close) : formatMessage(messages.open)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  handleMFIOFieldChange(signalId, 'expanded')
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={'MFIO: ' + parseInt(signalId) + (name ? ' | ' + name : '')}
      />
      <Collapse in={expanded}>
        <CardContent
          classes={{
            root: classes.contentRoot
          }}
          style={{ paddingBottom: '16px' }}
        >
          <Grid container spacing={2}>
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={pinFunctionErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.pinFunction)}</InputLabel>
                  <Select
                    name={MFIO_FIELDS.pinFunction}
                    onChange={event => {
                      handleMFIOFieldChange(signalId, MFIO_FIELDS.pinFunction, event.target.value)
                    }}
                    value={pinFunction}
                  >
                    {signalId === 0
                      ? optionsForMFIOItemSelects.pinFunction0.map((option, index) => (
                        <MenuItem key={index} disabled={option.disabled} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                      : optionsForMFIOItemSelects.pinFunction1.map((option, index) => (
                        <MenuItem key={index} disabled={option.disabled} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{pinFunctionErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.pinFunction)}</Typography>
                <label>{optionsForMFIOItemSelects.pinFunction0[pinFunction].label}</label>
              </Grid>
            )}
            {pinFunction !== 3 && returnValueTypes()}
            {pinFunction === 3 && returnFields()}
          </Grid>
          {pinFunction !== 3 && returnFields()}
        </CardContent>
      </Collapse>
    </Card>
  )
}

MFIO.propTypes = {
  averageValue: PropTypes.number.isRequired,
  bias: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  biasErrorMessage: PropTypes.string,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  cycleTimeDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cycleTimeDelayErrorMessage: PropTypes.string,
  dataLoggingEnable: PropTypes.bool.isRequired,
  decimals: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalsErrorMessage: PropTypes.string,
  defaultPowerUp: PropTypes.bool,
  defaultPowerUpErrorMessage: PropTypes.string,
  digThreshHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  digThreshHighErrorMessage: PropTypes.string,
  digThreshLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  digThreshLowErrorMessage: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  handleMFIODelete: PropTypes.func.isRequired,
  handleMFIOFieldChange: PropTypes.func.isRequired,
  inputRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRangeErrorMessage: PropTypes.string,
  inputSignal: PropTypes.string,
  inputSignalErrorMessage: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  lastValue: PropTypes.number.isRequired,
  logCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logCycleErrorMessage: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  multiplier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiplierErrorMessage: PropTypes.string,
  name: PropTypes.string,
  nameErrorMessage: PropTypes.string,
  noErrorInSignal: PropTypes.bool.isRequired,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetErrorMessage: PropTypes.string,
  pinFunction: PropTypes.number,
  pinFunctionErrorMessage: PropTypes.string,
  powerManagementEnable: PropTypes.number.isRequired,
  shutdownDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  shutdownDelayErrorMessage: PropTypes.string,
  signalId: PropTypes.number.isRequired,
  unit: PropTypes.string,
  unitErrorMessage: PropTypes.string
}

MFIO.defaultProps = {
  bias: '',
  biasErrorMessage: '',
  cycleTimeDelayErrorMessage: '',
  decimals: '',
  decimalsErrorMessage: '',
  defaultPowerUp: false,
  defaultPowerUpErrorMessage: '',
  digThreshHigh: '',
  digThreshHighErrorMessage: '',
  digThreshLow: '',
  digThreshLowErrorMessage: '',
  inputRange: '',
  inputRangeErrorMessage: '',
  inputSignal: '',
  inputSignalErrorMessage: '',
  logCycle: '',
  logCycleErrorMessage: '',
  multiplier: '',
  multiplierErrorMessage: '',
  name: '',
  nameErrorMessage: '',
  offset: '',
  offsetErrorMessage: '',
  pinFunction: 0,
  pinFunctionErrorMessage: '',
  shutdownDelayErrorMessage: '',
  unit: '',
  unitErrorMessage: ''
}

export default withStyles(styles)(injectIntl(React.memo(MFIO)))
