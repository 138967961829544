import { defineMessages } from 'react-intl'

const namespace = 'LinechartSettings'

const messages = defineMessages({
  linechartWidget: {
    id: `${namespace}.linechartWidget`,
    description: 'Line chart widget',
    defaultMessage: 'LINE CHART WIDGET'
  },
  linechartWidgetSettings: {
    id: `${namespace}.linechartWidgetSettings`,
    description: 'Line chart widget settings',
    defaultMessage: 'Line chart widget settings.'
  },
  dinamicValue: {
    id: `${namespace}.dinamicValue`,
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  mustHaveAValue: {
    id: `${namespace}.mustHaveAValue`,
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Value widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Value widget settings save button text',
    defaultMessage: 'Save'
  },
  notSupportedMachine: {
    id: `${namespace}.notSupportedMachine`,
    description: '',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: `${namespace}.noDataAvailable`,
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: `${namespace}.noSignal`,
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  otherLineChartWidgetSettings: {
    id: `${namespace}.otherLineChartWidgetSettings`,
    description: 'Line chart widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  maxNumberOfDecimals: {
    id: `${namespace}.maxNumberOfDecimals`,
    description: 'Number of decimals',
    defaultMessage: 'Max no. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: `${namespace}.onlyZeroOrHigherIsAllowed`,
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  maxNumberOfDecimalsCantBeHigherThan: {
    id: `${namespace}.maxNumberOfDecimalsCantBeHigherThan`,
    description: 'Max. no. of decimals can not be higher than',
    defaultMessage: 'Max. no. of decimals can not be higher than: {max}'
  }
})

export default messages
