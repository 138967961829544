import { defineMessages } from 'react-intl'

const messages = defineMessages({
  lastOneHour: {
    id: 'BasicSignalsChartSettings.lastOneHour',
    description: 'Last one hour',
    defaultMessage: 'Last 1 hour'
  },
  lastThreeHours: {
    id: 'BasicSignalsChartSettings.lastThreeHours',
    description: 'Last three hours',
    defaultMessage: 'Last 3 hours'
  },
  lastSixHours: {
    id: 'BasicSignalsChartSettings.lastSixHours',
    description: 'Last six hours',
    defaultMessage: 'Last 6 hours'
  },
  lastTwelveHours: {
    id: 'BasicSignalsChartSettings.lastTwelveHours',
    description: 'Last twelve hours',
    defaultMessage: 'Last 12 hours'
  },
  lastTwentyFourHours: {
    id: 'BasicSignalsChartSettings.lastTwentyFourHours',
    description: 'Last twenty four hours',
    defaultMessage: 'Last 24 hours'
  },
  canBusSignals: {
    id: 'BasicSignalsChartSettings.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  gpsSignals: {
    id: 'BasicSignalsChartSettings.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  mfioSignals: {
    id: 'BasicSignalsChartSettings.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: 'BasicSignalsChartSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  aggregation: {
    id: 'BasicSignalsChartSettings.aggregation',
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  none: {
    id: 'BasicSignalsChartSettings.none',
    description: 'None aggregation type',
    defaultMessage: 'None'
  },
  avg: {
    id: 'BasicSignalsChartSettings.avg',
    description: 'Average aggregation type',
    defaultMessage: 'Average'
  },
  min: {
    id: 'BasicSignalsChartSettings.min',
    description: 'Minimum aggregation type',
    defaultMessage: 'Minimum'
  },
  max: {
    id: 'BasicSignalsChartSettings.max',
    description: 'Maximum aggregation type',
    defaultMessage: 'Maximum'
  },
  valueType: {
    id: 'BasicSignalsChartSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  valuenone: {
    id: 'BasicSignalsChartSettings.valuenone',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueavg: {
    id: 'BasicSignalsChartSettings.valueavg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valuemin: {
    id: 'BasicSignalsChartSettings.valuemin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valuemax: {
    id: 'BasicSignalsChartSettings.valuemax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  defaultTimeRange: {
    id: 'BasicSignalsChartSettings.defaultTimeRange',
    description: 'Default time range',
    defaultMessage: 'Default time range'
  },
  loadingConfiguratedSignals: {
    id: 'BasicSignalsChartSettings.loadingConfiguratedSignals',
    description: 'Loading configurated signals',
    defaultMessage: 'Loading configurated signals'
  },
  notSupportedMachine: {
    id: 'BasicSignalsChartSettings.notSupportedMachine',
    description: '',
    defaultMessage: 'Not supported machine'
  },
  otherHistoricWidgetSettings: {
    id: 'BasicSignalsChartSettings.otherHistoricWidgetSettings',
    description: 'Historic widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  maxNumberOfDecimals: {
    id: 'BasicSignalsChartSettings.maxNumberOfDecimals',
    description: 'Number of decimals',
    defaultMessage: 'Max no. of decimals'
  },
  basicSignalsChartSettings: {
    id: 'BasicSignalsChartSettings.basicSignalsChartSettings',
    description: 'Basic signals chart settings',
    defaultMessage: 'Basic signals chart settings:'
  }
})

export default messages
