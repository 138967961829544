import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import Alert from 'components/Alert'
import SmsServiceStateChip from 'components/SmsServiceStateChip'

import { getEditUsersUrl } from '../url'
import './Users.css'
import messages from './messages'
import { mapToTableUsers } from './utils'

import { client } from 'utils/http'

import 'react-tippy/dist/tippy.css'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class UsersTable extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    let userMessagesSuccessClass
    let userMessagesSuccessText

    const {
      location: { state: locationState = {} }
    } = props

    let defaultTableProps = { page: 1, length: 10 }

    switch (locationState.action) {
      case 'update':
        userMessagesSuccessClass = ''
        userMessagesSuccessText = this.formatMessage(messages.userUpdatedSuccessfully)
        break
      case 'create':
        userMessagesSuccessClass = ''
        userMessagesSuccessText = this.formatMessage(messages.userCreatedSuccessfully)
        break
      case 'back':
        userMessagesSuccessClass = 'hidden'
        defaultTableProps = { ...defaultTableProps, ...locationState.payload }
        break
      default:
        userMessagesSuccessClass = 'hidden'
        userMessagesSuccessText = ''
    }

    const { page, length } = defaultTableProps

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      count: 0,
      dialogOpen: false,
      dialogSelectedUserToDeleteId: '',
      dialogSelectedUserToDeleteTitle: '',
      elementsPerPage: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      length: 10,
      noDataText: <CircularProgress />,
      page,
      start: (page - 1) * length,
      userMessagesDangerClass: 'hidden',
      userMessagesDangerText: '',
      userMessagesDangerTitle: '',
      userMessagesSuccessClass,
      userMessagesSuccessText,
      users: [],
      groupOwnerId: ''
    }
  }

  componentDidMount() {
    const { location, history, selectedGroup } = this.props
    const { start, length } = this.state

    if (location.state) {
      history.replace(location.pathname, undefined)
    }
    if (Object.keys(selectedGroup).length > 0) {
      this.getUsers(start, length)
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedGroup, location, history } = this.props
    const { start, length } = this.state

    if (Object.keys(prevProps.selectedGroup).length === 0 && Object.keys(selectedGroup).length > 0) {
      this.getUsers(start, length)
    } else if (prevProps.selectedGroup.id !== selectedGroup.id) {
      this.setState(
        {
          start: 0,
          page: 1
        },
        () => {
          this.getUsers(0, length)
        }
      )
    }
    if (location.state?.action === 'create') {
      history.replace(location.pathname, undefined)
      this.setState(
        {
          userMessagesSuccessClass: '',
          userMessagesSuccessText: this.formatMessage(messages.userCreatedSuccessfully)
        },
        () => {
          this.getUsers(start, length)
        }
      )
    }
  }

  getUsers = (start, sizePerPage) => {
    const { getDetailedUsers, selectedGroup } = this.props
    this.setState(
      {
        users: [],
        groupOwnerId: '',
        noDataText: <CircularProgress />
      },
      async () => {
        try {
          const roleUsersResponse = await getDetailedUsers(selectedGroup.id, sizePerPage, start)

          const {
            total = 1,
            users: roleUsers,
            groupDetails: { groupOwnerId }
          } = roleUsersResponse.data.groupUsers
          const userExtended = mapToTableUsers(roleUsers)

          this.setState({
            users: userExtended,
            groupOwnerId,
            count: total,
            noDataText: this.formatMessage(messages.noUsersMeetThisFilters),
            alertMessages: false,
            alertMessagesType: '',
            alertMessagesTitle: '',
            alertMessagesText: ['']
          })
        } catch (response) {
          const error = response.error
          switch (error.response.status) {
            case 400:
              const errorReceived =
                typeof error.response.data === 'string' ? error.response.data : error.response.data.message
              if (errorReceived.includes('ACTION IS NOT ALLOWED')) {
                this.setState({
                  noDataText: this.formatMessage(messages.noUsersMeetThisFilters)
                })
              } else {
                this.setState({
                  noDataText: this.formatMessage(messages.noUsersMeetThisFilters),
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '400'
                  }),
                  alertMessagesText: [this.formatMessage(messages.error400Message)]
                })
              }
              break
            case 404:
              this.setState({
                noDataText: this.formatMessage(messages.noUsersMeetThisFilters),
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
              break
            default:
              this.setState({
                noDataText: this.formatMessage(messages.noUsersMeetThisFilters),
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
          }
        }
      }
    )
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedUserToDeleteTitle: '',
      dialogSelectedUserToDeleteId: ''
    })
  }

  deleteUser = row => {
    const { users } = this.state
    const prop = 'id'
    const theUser = users.filter(user => {
      return user[prop] === row[prop]
    })
    const userTitle = theUser[0].lastName + ', ' + theUser[0].firstName + ' (' + theUser[0].email + ')'

    this.setState({
      dialogOpen: true,
      dialogSelectedUserToDeleteTitle: userTitle,
      dialogSelectedUserToDeleteId: row[prop]
    })
  }

  handleDelete = async () => {
    const { selectedGroup, user, changeGroup, deleteUser } = this.props
    const { dialogSelectedUserToDeleteId, users, start, length } = this.state

    const selectedUser = users.find(u => u.id === dialogSelectedUserToDeleteId)
    const params = [selectedGroup.id, [selectedUser.email]]

    try {
      this.setState({
        isDeleting: true
      })
      await client.removeUserFromAllAssignedRuleInstances(selectedGroup.id, selectedUser.email)
      await deleteUser(...params)

      const { email } = users.find(u => u.id === dialogSelectedUserToDeleteId)
      if (user === email) await changeGroup('default')

      this.setState(
        {
          dialogOpen: false,
          dialogSelectedUserToDeleteId: '',
          dialogSelectedUserToDeleteTitle: '',
          isDeleting: false,
          userMessagesDangerClass: 'hidden',
          userMessagesDangerText: '',
          userMessagesDangerTitle: '',
          userMessagesSuccessClass: '',
          userMessagesSuccessText: this.formatMessage(messages.userDeletedSuccessfully)
        },
        () => {
          this.getUsers(start, length)
        }
      )
    } catch (response) {
      const error = response.error
      switch (error.response.status) {
        case 400:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.error, {
              number: '400'
            }),
            userMessagesDangerText: this.formatMessage(messages.cannotDeleteOwner)
          })
          break
        case 404:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.error, {
              number: '404'
            }),
            userMessagesDangerText: this.formatMessage(messages.error404Message)
          })
          break
        case 428:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.error, {
              number: '428'
            }),
            userMessagesDangerText: this.formatMessage(messages.error428Message)
          })
          break
        case 500:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.error, {
              number: '500'
            }),
            userMessagesDangerText: error.response.data.error_description + error.response.data.message
          })
          break
        case 502:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.error, {
              number: '502'
            }),
            userMessagesDangerText: this.formatMessage(messages.error502Message)
          })
          break
        default:
          this.setState({
            isDeleting: false,
            dialogOpen: false,
            dialogSelectedUserToDeleteTitle: '',
            dialogSelectedUserToDeleteId: '',
            userMessagesSuccessClass: 'hidden',
            userMessagesSuccessText: '',
            userMessagesDangerClass: '',
            userMessagesDangerTitle: this.formatMessage(messages.errorUndefinedTitle),
            userMessagesDangerText: this.formatMessage(messages.errorUndefinedMessage)
          })
      }
    }
  }

  handleCloseSuccessAlert = () => {
    this.setState({
      userMessagesSuccessClass: 'hidden',
      userMessagesSuccessText: ''
    })
  }

  handleCloseDangerAlert = () => {
    this.setState({
      userMessagesDangerClass: 'hidden',
      userMessagesDangerTitle: '',
      userMessagesDangerText: ''
    })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        users: []
      },
      () => {
        this.getUsers((page - 1) * sizePerPage, sizePerPage)
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    const { start } = this.state
    this.setState(
      {
        length: sizePerPage,
        users: []
      },
      () => {
        this.getUsers(start, sizePerPage)
      }
    )
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  formatSmsService = cell => {
    return <SmsServiceStateChip state={cell} />
  }

  formatEnabled = cell => {
    return cell ? this.formatMessage(messages.yes) : this.formatMessage(messages.no)
  }

  showActions = () => {
    const { canDeleteUser, canEditUser, selectedGroup } = this.props
    const notAllowedGroups = ['DANFOSS DEFAULT GROUP']
    if (
      canDeleteUser ||
      canEditUser ||
      selectedGroup.name && !notAllowedGroups.includes(selectedGroup.name.toUpperCase())
    ) {
      return (
        <TableHeaderColumn
          dataAlign='center'
          dataField='id'
          dataFormat={this.formatActionsDetail}
          headerAlign='center'
          width='10%'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  formatActionsDetail = (cell, row) => {
    const { selectedGroup, canEditUser, canDeleteUser, classes } = this.props
    const { length, page, users, groupOwnerId } = this.state

    const isDeleteButtonDisabled = groupOwnerId === row.id
    return (
      <div>
        {selectedGroup.name.toUpperCase() !== 'DANFOSS DEFAULT GROUP' && canEditUser && (
          <Link
            className='button-link'
            style={{ marginLeft: 10 }}
            to={{
              pathname: getEditUsersUrl(cell),
              state: { roleId: row.roleId, email: row.email, page, length }
            }}
          >
            <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.editUser)}>
              <div style={{ padding: 0, display: '-webkit-inline-box' }}>
                <IconButton style={{ padding: '5px' }}>
                  <EditIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Link>
        )}
        {users.length > 1 && canDeleteUser && !isDeleteButtonDisabled && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.deleteUser)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton onClick={() => this.deleteUser(row)} style={{ padding: '5px' }}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      filter: true,
      pagination: true,
      search: false,
      sizePerPage: true,
      sort: false
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { canReadRoles } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      count,
      dialogOpen,
      dialogSelectedUserToDeleteTitle,
      elementsPerPage,
      isDeleting,
      length,
      noDataText,
      page,
      userMessagesDangerClass,
      userMessagesDangerText,
      userMessagesDangerTitle,
      userMessagesSuccessClass,
      userMessagesSuccessText,
      users
    } = this.state

    const tableOptions = {
      noDataText,
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: elementsPerPage,
      sizePerPage: length,
      page,
      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true
    }

    const enabledFilterOptions = {
      yes: this.formatMessage(messages.yes),
      no: this.formatMessage(messages.no)
    }

    return (
      <div className='users'>
        <div className={userMessagesSuccessClass}>
          <div className='alert alert-success alert-dismissible animated fadeIn'>
            <button aria-label='Close' className='close' onClick={this.handleCloseSuccessAlert}>
              <span aria-hidden='true'>×</span>
            </button>
            <span className='h5'>{userMessagesSuccessText}</span>
          </div>
        </div>
        <div className={userMessagesDangerClass}>
          <div className='alert alert-danger alert-dismissible animated fadeIn'>
            <button aria-label='Close' className='close' onClick={this.handleCloseDangerAlert}>
              <span aria-hidden='true'>×</span>
            </button>
            <h4>{userMessagesDangerTitle}</h4>
            <span className='h5'>{userMessagesDangerText}</span>
          </div>
        </div>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              csvFileName='xxx.csv'
              data={users}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={this.remote}
              search={false}
              searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
              striped={false}
            >
              <TableHeaderColumn dataField='firstName' isKey width='15%'>
                {this.formatMessage(messages.firstName)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='lastName' width='15%'>
                {this.formatMessage(messages.lastName)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='email' width='25%'>
                {this.formatMessage(messages.email)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='smsService' dataFormat={this.formatSmsService} width='12%'>
                {this.formatMessage(messages.smsService)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='enabled'
                dataFormat={this.formatEnabled}
                formatExtraData={enabledFilterOptions}
                headerAlign='left'
                width='10%'
              >
                {this.formatMessage(messages.enabled)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='roleDescription' hidden={!canReadRoles} width='12%'>
                {this.formatMessage(messages.role)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpen}>
          <DialogTitle>{this.formatMessage(messages.confirmUserToDelete)}</DialogTitle>
          <DialogContent>{dialogSelectedUserToDeleteTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose}>
              {this.formatMessage(messages.cancel)}
            </Button>
            <Button
              className='delete-button'
              disabled={isDeleting}
              onClick={this.handleDelete}
              style={{ marginLeft: 5 }}
            >
              {isDeleting && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              {this.formatMessage(messages.delete)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

UsersTable.propTypes = {
  canDeleteUser: PropTypes.bool.isRequired,
  canEditUser: PropTypes.bool.isRequired,
  canReadRoles: PropTypes.bool.isRequired,
  changeGroup: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  getDetailedUsers: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(UsersTable)))
