import { utcTimeToBrowserLocal } from 'utils/timeFormat'

export const getCSVAdaptedDevicesData = rawDevicesData =>
  rawDevicesData.map(device => {
    let mobileSignal = ''
    if (device?.mobileSignal?.mobileSignalQualityCategory && device?.mobileSignal?.accessTechnologyShort) {
      mobileSignal = `${device.mobileSignal.mobileSignalQualityCategory} - ${device.mobileSignal.accessTechnologyShort}`
    }
    return {
      id: device.id,
      name: device.name,
      machineModel: device.machine_model,
      machineType: device.machine_type,
      machineBrand: device.machine_brand,
      machineSerialNumber: device.machine_serial_number,
      deviceType: device.device_type,
      eid: device.eid,
      lastTelemetryMessage: device?.last_activity_time || '',
      firmwareVersion: device.main_firmware_version,
      deviceConfiguration: device?.Configuration?.name || '',
      deviceConfigurationVersion: device?.Configuration?.versionNumber,
      mobileSignal,
      lastGPSMessage: device?.lastGPSTimestamp ? utcTimeToBrowserLocal(device.lastGPSTimestamp) : '',
      planExpirationDate: device?.Dataplan ? utcTimeToBrowserLocal(device.Dataplan.end_date) :''
    }
  })
