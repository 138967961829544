import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { MAX_DECIMALS } from 'utils/constants'

import { BasicSignalsChartSettings, AdvancedSignalsChartSettings } from './HistoricTypeSettings'
import { BASIC, ADVANCED } from './constants'
import messages from './messages'
import { getDefaultSettingsByHistoricType } from './utils'

const styles = () => ({
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
})

class HistoricSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    const {
      data: { data }
    } = props

    const historicType = data === ADVANCED ? ADVANCED : BASIC
    const settings = getDefaultSettingsByHistoricType(props, historicType)

    this.state = {
      settings,
      configurationLoading: false,
      historicType
    }
  }

  handleHistoricChange = (event, value) => {
    const { historicType } = this.state
    if (historicType !== value) {
      this.setState({ historicType: value, settings: getDefaultSettingsByHistoricType({}, value) })
    }
  }

  handleSettingsChange = updatedSettings => {
    this.setState(({ settings }) => ({ settings: { ...settings, ...updatedSettings } }))
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleBasicSignalsChartErrors = settings => {
    const { numberOfDecimals, dinamicData, selectedTimeRange } = settings
    let error = false

    if (numberOfDecimals === '') {
      error = true
      this.handleSettingsChange({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else if (numberOfDecimals < 0) {
      error = true
      this.handleSettingsChange({
        numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
      })
    } else {
      if (numberOfDecimals > MAX_DECIMALS) {
        error = true
        this.handleSettingsChange({
          numberOfDecimalsErrorText: this.formatMessage(messages.maxNumberOfDecimalsCantBeHigherThan, {
            max: MAX_DECIMALS
          })
        })
      }
    }

    if (dinamicData.length > 0 && selectedTimeRange === -1) {
      error = true
      this.handleSettingsChange({
        selectedTimeRangeErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    }
    return error
  }

  handleAdvancedSignalsChartErrors = settings => {
    const { widgetAdvancedSignals, selectedTimeRange } = settings
    let error = false
    if (widgetAdvancedSignals.length > 0 && selectedTimeRange === -1) {
      error = true
      this.handleSettingsChange({
        selectedTimeRangeErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    }
    return error
  }

  handleSaveSettings = () => {
    const { historicType, settings } = this.state
    let error
    const widgetData = {
      data: historicType,
      settings
    }

    switch (historicType) {
      case BASIC:
        error = this.handleBasicSignalsChartErrors(settings)
        widgetData.settings = {
          data: settings.dinamicData,
          numberOfDecimals: settings.numberOfDecimals,
          selectedTimeRange: settings.selectedTimeRange,
          aggregations: settings.aggregations,
          valueTypes: settings.valueTypes
        }
        break
      case ADVANCED:
        error = this.handleAdvancedSignalsChartErrors(settings)
        widgetData.settings = {
          signals: settings.widgetAdvancedSignals,
          type: settings.type,
          selectedTimeRange: settings.selectedTimeRange
        }
        break
    }

    if (!error) {
      const { saveSettings } = this.props
      saveSettings(widgetData)
    }
  }

  renderHistoricTypedSettings = () => {
    const { classes, eid, advancedSignalsData, dinamicData } = this.props
    const { configurationLoading, settings, historicType } = this.state

    const boxShadow =
      '0px 1px 5px 0px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%),0px 3px 1px -2px rgb(0 0 0 / 12%)'

    return (
      <React.Fragment>
        <Tabs
          classes={{ indicator: classes.indicator }}
          onChange={this.handleHistoricChange}
          style={{
            boxShadow,
            maxWidth: 'fit-content'
          }}
          value={historicType}
        >
          <Tab
            label={this.formatMessage(messages.basic)}
            style={{ boxShadow, backgroundColor: historicType === BASIC ? 'white' : '#dcdcdc' }}
            value={BASIC}
          />
          <Tab
            label={this.formatMessage(messages.advanced)}
            style={{ boxShadow, backgroundColor: historicType === ADVANCED ? 'white' : '#dcdcdc' }}
            value={ADVANCED}
          />
        </Tabs>
        {historicType === BASIC ? (
          <BasicSignalsChartSettings
            configurationLoading={configurationLoading}
            dynamicSignals={dinamicData}
            onSettingsChange={this.handleSettingsChange}
            {...settings}
          />
        ) : (
          <AdvancedSignalsChartSettings
            advancedSignalsData={advancedSignalsData}
            eid={eid}
            onSettingsChange={this.handleSettingsChange}
            {...settings}
          />
        )}
      </React.Fragment>
    )
  }

  render() {
    const { configurationLoading } = this.state

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.historicWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>{this.renderHistoricTypedSettings()}</DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={configurationLoading} onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

HistoricSettings.propTypes = {
  advancedSignalsData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(HistoricSettings))
