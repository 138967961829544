import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { ListSubheader } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import DataConsumptionEstimation from 'modules/configurationsCS100/DataConsumptionEstimation'
import MFIO from 'modules/configurationsCS100/MFIO'
import Signal from 'modules/configurationsCS100/Signal'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'


const ViewConfigurationForm = ({
  ancestorConfigurations,
  configForDataEstimation,
  configuration,
  configurationsUrl,
  createdDate,
  downloadConfiguration,
  intl,
  loadingConfiguration
}) => {
  const { formatMessage } = intl

  let coordinates = ''
  if (configuration.gpsEnabled && configuration.gpsAltitude)
    coordinates = coordinates + formatMessage(messages.altitude) + ', '
  if (configuration.gpsEnabled && configuration.gpsHeading)
    coordinates = coordinates + formatMessage(messages.heading) + ', '
  if (configuration.gpsEnabled && configuration.gpsSpeed)
    coordinates = coordinates + formatMessage(messages.speed) + ', '
  coordinates = coordinates.slice(0, -2)

  return (
    <div className='container-fluid'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataConsumptionEstimation configuration={configForDataEstimation} loading={loadingConfiguration} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card style={{ height: '100%', borderRadius: 0 }}>
            <CardHeader title={formatMessage(messages.generalSettings)} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.createdDate)}</Typography>
                  <label>{createdDate}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.baudRate1)}</Typography>
                  <label>{configuration.baudRate1}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.baudRate2)}</Typography>
                  <label>{configuration.baudRate2}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.nodeId)}</Typography>
                  <label>{configuration.nodeId}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.loggingUploadPeriod)}</Typography>
                  <label>{configuration.loggingUploadPeriod}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.loggingBufferThreshold)}</Typography>
                  <label>{configuration.loggingBufferThreshold}</label>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{formatMessage(messages.dataLogging)}</Typography>
                  <label>{configuration.dataLoggingEnable.toString()}</label>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card style={{ height: '100%', borderRadius: 0 }}>
            <CardHeader title={formatMessage(messages.gpsSettings)} />
            <CardContent>
              <div className='row'>
                <div className='col-md-6'>
                  <Typography>{formatMessage(messages.enableGPSLogging)}</Typography>
                  <label>{coordinates}</label>
                </div>
                <div className='col-md-6'>
                  {/*<Typography>{formatMessage(messages.frequency)}</Typography>*/}
                  <Typography>{formatMessage(messages.loggingTime)}</Typography>
                  <label>{configuration.gpsEnabled ? configuration.gpsFrequency : 0}</label>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Grid container justify='space-between'>
              <Grid item>
                <Typography component='h3' style={{ margin: '14px' }} variant='h5'>
                  {formatMessage(messages.mfio)}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {configuration.MFIO.map(current => {
                const noErrorInSignal =
                  current.biasErrorMessage === '' &&
                  current.decimalsErrorMessage === '' &&
                  current.defaultPowerUpErrorMessage === '' &&
                  current.digThreshHighErrorMessage === '' &&
                  current.digThreshLowErrorMessage === '' &&
                  current.inputRangeErrorMessage === '' &&
                  current.inputSignalErrorMessage === '' &&
                  current.logCycleErrorMessage === '' &&
                  current.multiplierErrorMessage === '' &&
                  current.nameErrorMessage === '' &&
                  current.offsetErrorMessage === '' &&
                  current.pinFunctionErrorMessage === '' &&
                  current.unitErrorMessage === ''
                return (
                  <React.Fragment key={current.signalId}>
                    <Divider light />
                    <MFIO
                      averageValue={current.averageValue}
                      bias={current.bias}
                      biasErrorMessage={current.biasErrorMessage}
                      cycleTimeDelay={configuration.cycleTimeDelay}
                      cycleTimeDelayErrorMessage={configuration.cycleTimeDelayErrorMessage}
                      dataLoggingEnable={configuration.dataLoggingEnable}
                      decimals={current.decimals}
                      decimalsErrorMessage={current.decimalsErrorMessage}
                      defaultPowerUp={current.defaultPowerUp}
                      defaultPowerUpErrorMessage={current.defaultPowerUpErrorMessage}
                      digThreshHigh={current.digThreshHigh}
                      digThreshHighErrorMessage={current.digThreshHighErrorMessage}
                      digThreshLow={current.digThreshLow}
                      digThreshLowErrorMessage={current.digThreshLowErrorMessage}
                      expanded={current.expanded}
                      inputRange={current.inputRange}
                      inputRangeErrorMessage={current.inputRangeErrorMessage}
                      inputSignal={current.inputSignal}
                      inputSignalErrorMessage={current.inputSignalErrorMessage}
                      isEditable={false}
                      lastValue={current.lastValue}
                      logCycle={current.logCycle}
                      logCycleErrorMessage={current.logCycleErrorMessage}
                      maxValue={current.maxValue}
                      minValue={current.minValue}
                      multiplier={current.multiplier}
                      multiplierErrorMessage={current.multiplierErrorMessage}
                      name={current.name}
                      nameErrorMessage={current.nameErrorMessage}
                      noErrorInSignal={noErrorInSignal}
                      offset={current.offset}
                      offsetErrorMessage={current.offsetErrorMessage}
                      pinFunction={current.pinFunction}
                      pinFunctionErrorMessage={current.pinFunctionErrorMessage}
                      powerManagementEnable={configuration.powerManagementEnable}
                      powerManagementEnableErrorMessage={configuration.powerManagementEnableErrorMessage}
                      shutdownDelay={configuration.shutdownDelay}
                      shutdownDelayErrorMessage={configuration.shutdownDelayErrorMessage}
                      signalId={current.signalId}
                      unit={current.unit}
                      unitErrorMessage={current.unitErrorMessage}
                    />
                  </React.Fragment>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Grid container justify='space-between'>
              <Grid item>
                <Typography component='h3' style={{ margin: '14px' }} variant='h5'>
                  {formatMessage(messages.canbus)}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {configuration.signals.map(current => {
                const noErrorInSignal =
                  current.canPortErrorMessage === '' &&
                  current.addressErrorMessage === '' &&
                  current.idMaskErrorMessage === '' &&
                  current.canIdErrorMessage === '' &&
                  current.nameErrorMessage === '' &&
                  current.unitErrorMessage === '' &&
                  current.startBitErrorMessage === '' &&
                  current.offsetErrorMessage === '' &&
                  current.multiplierErrorMessage === '' &&
                  current.frequencyErrorMessage === ''
                return (
                  <React.Fragment key={current.signalId}>
                    <Divider light />
                    <Signal
                      address={current.address}
                      addressErrorMessage={current.addressErrorMessage}
                      canId={current.canId}
                      canIdErrorMessage={current.canIdErrorMessage}
                      canPort={current.canPort}
                      canPortErrorMessage={current.canPortErrorMessage}
                      dataLoggingEnable={configuration.dataLoggingEnable}
                      expanded={current.expanded}
                      frequency={current.frequency}
                      frequencyErrorMessage={current.frequencyErrorMessage}
                      idMask={current.idMask}
                      idMaskErrorMessage={current.idMaskErrorMessage}
                      isEditable={false}
                      lengthOfBits={current.lengthOfBits}
                      lengthOfBitsErrorMessage={current.lengthOfBitsErrorMessage}
                      multiplier={current.multiplier}
                      multiplierErrorMessage={current.multiplierErrorMessage}
                      name={current.name}
                      nameErrorMessage={current.nameErrorMessage}
                      noErrorInSignal={noErrorInSignal}
                      offset={current.offset}
                      offsetErrorMessage={current.offsetErrorMessage}
                      signalId={current.signalId}
                      signed={current.signed}
                      startBit={current.startBit}
                      startBitErrorMessage={current.startBitErrorMessage}
                      unit={current.unit}
                      unitErrorMessage={current.unitErrorMessage}
                    />
                  </React.Fragment>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Grid container justify='space-between'>
              <Grid item xs={12}>
                <Typography component='h3' style={{ margin: '14px' }} variant='h5'>
                  {formatMessage(messages.history)}
                </Typography>
              </Grid>
              {ancestorConfigurations.length > 0 ? (
                <Grid item xs={6}>
                  <List subheader={<ListSubheader>{formatMessage(messages.version)}</ListSubheader>}>
                    {ancestorConfigurations.map(config => {
                      return (
                        <div key={'versionNumber' + config.id}>
                          <Divider />
                          <ListItem>{'v.' + config.versionNumber}</ListItem>
                        </div>
                      )
                    })}
                  </List>
                </Grid>
              ) : (
                <Typography component='h6' style={{ marginLeft: '14px', marginBottom: '14px' }} variant='subtitle1'>
                  {formatMessage(messages.parentRoot)}
                </Typography>
              )}
              {ancestorConfigurations.length > 0 && (
                <Grid item xs={6}>
                  <List subheader={<ListSubheader>{formatMessage(messages.published)}</ListSubheader>}>
                    {ancestorConfigurations.map(config => {
                      return (
                        <div key={'createdAt' + config.id}>
                          <Divider />
                          <ListItem>{utcTimeToBrowserLocal(config.createdAt)}</ListItem>
                        </div>
                      )
                    })}
                  </List>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid container item justify='flex-end'>
          <Link className='button-link' to={configurationsUrl}>
            <Button className='cancel-button' style={{ marginRight: 10 }}>
              {formatMessage(messages.cancel)}
            </Button>
          </Link>
          <Button className='primary-action-button' onClick={downloadConfiguration}>
            {formatMessage(messages.download)}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

ViewConfigurationForm.propTypes = {
  ancestorConfigurations: PropTypes.array.isRequired,
  configForDataEstimation: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  downloadConfiguration: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingConfiguration: PropTypes.bool.isRequired
}

export default injectIntl(ViewConfigurationForm)
