import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import DataChip from 'components/DataChip'
import { client, logError } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from '../messages'

class ActionCancelDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidUpdate(prevProps) {
    const { open, task } = this.props

    if (!prevProps.open && open) {
      client
        .cancelTask(task.hashId)
        .then(() => {})
        .catch(response => {
          logError(response)
        })
    }
  }

  render() {
    const { intl, onClose, open, task } = this.props

    return (
      <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open}>
        <DialogTitle disableTypography>
          <Grid alignItems='center' container>
            <Grid item xs={10}>
              <Typography variant='h5'>{task.nodeName}</Typography>
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton aria-label='close' onClick={onClose} style={{ padding: '3px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <p style={{ marginBottom: 0 }}>{task.actionDescription}</p>
        </DialogTitle>
        <DialogContent style={{ fontSize: 16 }}>
          <hr style={{ marginTop: 0 }} />
          <p>
            <strong>{this.formatMessage(messages.original)}</strong>{' '}
            {this.formatMessage(messages.taskCreatedByOriginal)}{' '}
            <a href={'mailto:' + task.userEmail}>
              <strong>
                {task.userFirstName} {task.userLastName}
              </strong>
            </a>{' '}
            {this.formatMessage(messages.on)} {utcTimeToBrowserLocal(task.createdDate)}
          </p>
          <div style={{ margin: '1em 0' }}>
            <DataChip chipText={this.formatMessage(messages.canceled)} intl={intl} />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

ActionCancelDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
}

export default injectIntl(ActionCancelDialog)
