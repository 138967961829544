import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { MAX_DECIMALS } from 'utils/constants'
import { generateDeviceData } from 'utils/deviceDataGenerator'

import { BasicSignalsChartSettings, AdvancedSignalsChartSettings } from './HistoricTypeSettings'
import { BASIC, ADVANCED } from './constants'
import messages from './messages'
import { getDefaultSettingsByHistoricType } from './utils'

const styles = () => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  },
  autocomplete: {
    marginTop: 15
  },
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
})

class HistoricSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    const {
      eid,
      devicesData,
      data: { data }
    } = props

    const deviceInfo = devicesData[eid] || {
      staticData: [],
      dinamicDataId: '',
      dinamicData: []
    }

    const historicType = data === ADVANCED ? ADVANCED : BASIC
    const settings = getDefaultSettingsByHistoricType(props, historicType)

    this.state = {
      settings,
      eid,
      devicesTableDisplayed: false,
      devicesButtonTextKey: 'changeMachine',
      deviceInfo,
      originalDevice: '',
      configurationLoading: false,
      historicType
    }
  }

  changeSelectedDevice = device => {
    this.setState({
      eid: device.EID,
      deviceInfo: generateDeviceData(device),
      originalDevice: device,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType),
      historicType: BASIC
    })
    this.handleSettingsChange({
      aggregations: [],
      valueTypes: [],
      dinamicData: []
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const deviceWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const deviceInfo = generateDeviceData(deviceWithConfig)
      return {
        deviceInfo,
        originalDevice: deviceWithConfig,
        configurationLoading: false
      }
    })
  }

  updateDeviceInfo = moreDeviceInfo => {
    this.setState(({ deviceInfo }) => ({ deviceInfo: { ...deviceInfo, ...moreDeviceInfo } }))
  }

  handleChangeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  handleHistoricChange = (event, value) => {
    const { historicType } = this.state
    if (historicType !== value) {
      this.setState({ historicType: value, settings: getDefaultSettingsByHistoricType({}, value) })
    }
  }

  handleSettingsChange = updatedSettings => {
    this.setState(({ settings }) => ({ settings: { ...settings, ...updatedSettings } }))
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleBasicSignalsChartErrors = settings => {
    const { numberOfDecimals, dinamicData, selectedTimeRange } = settings
    let error = false

    if (numberOfDecimals === '') {
      error = true
      this.handleSettingsChange({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else if (numberOfDecimals < 0) {
      error = true
      this.handleSettingsChange({
        numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
      })
    } else {
      if (numberOfDecimals > MAX_DECIMALS) {
        error = true
        this.handleSettingsChange({
          numberOfDecimalsErrorText: this.formatMessage(messages.maxNumberOfDecimalsCantBeHigherThan, {
            max: MAX_DECIMALS
          })
        })
      }
    }

    if (dinamicData.length > 0 && selectedTimeRange === -1) {
      error = true
      this.handleSettingsChange({
        selectedTimeRangeErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    }
    return error
  }

  handleAdvancedSignalsChartErrors = settings => {
    const { widgetAdvancedSignals, selectedTimeRange } = settings
    let error = false
    if (widgetAdvancedSignals.length > 0 && selectedTimeRange === -1) {
      error = true
      this.handleSettingsChange({
        selectedTimeRangeErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    }
    return error
  }

  handleSaveSettings = () => {
    const { historicType, settings } = this.state
    let error
    const widgetData = {
      data: historicType,
      settings
    }

    switch (historicType) {
      case BASIC:
        error = this.handleBasicSignalsChartErrors(settings)
        widgetData.settings = {
          data: settings.dinamicData,
          numberOfDecimals: settings.numberOfDecimals,
          selectedTimeRange: settings.selectedTimeRange,
          aggregations: settings.aggregations,
          valueTypes: settings.valueTypes
        }
        break
      case ADVANCED:
        error = this.handleAdvancedSignalsChartErrors(settings)
        widgetData.settings = {
          signals: settings.widgetAdvancedSignals,
          type: settings.type,
          selectedTimeRange: settings.selectedTimeRange
        }
        break
    }

    if (!error) {
      const { eid, deviceInfo } = this.state
      const { saveSettings } = this.props

      saveSettings(widgetData, [eid], deviceInfo)
    }
  }

  renderDeviceName = () => {
    const {
      deviceInfo: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      deviceInfo: { staticData }
    } = this.state

    const { classes } = this.props

    const data = staticData.find(({ name }) => name === 'hasConfiguration')

    let messageKey = ''
    let color = 'black'

    if (configurationLoading) {
      messageKey = 'Loading'
      color = '#f0ad4e'
    } else {
      if (data?.value) {
        messageKey = 'Available'
        color = 'green'
      } else {
        messageKey = 'NotAvailable'
        color = 'red'
      }
    }

    const configurationStatus = this.formatMessage(messages['configuration' + messageKey])

    return (
      <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
        <strong>{this.formatMessage(messages.configurationStatus)}: </strong>{' '}
        <span style={{ color }}>{configurationStatus}</span>
      </DialogContentText>
    )
  }

  renderHistoricTypedSettings = () => {
    const { classes } = this.props
    const { deviceInfo, configurationLoading, settings, historicType, eid } = this.state

    const boxShadow =
      '0px 1px 5px 0px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%),0px 3px 1px -2px rgb(0 0 0 / 12%)'

    return (
      <React.Fragment>
        <Tabs
          classes={{ indicator: classes.indicator }}
          onChange={this.handleHistoricChange}
          style={{
            boxShadow,
            maxWidth: 'fit-content'
          }}
          value={historicType}
        >
          <Tab
            label={this.formatMessage(messages.basic)}
            style={{ boxShadow, backgroundColor: historicType === BASIC ? 'white' : '#dcdcdc' }}
            value={BASIC}
          />
          <Tab
            label={this.formatMessage(messages.advanced)}
            style={{ boxShadow, backgroundColor: historicType === ADVANCED ? 'white' : '#dcdcdc' }}
            value={ADVANCED}
          />
        </Tabs>
        {historicType === BASIC ? (
          <BasicSignalsChartSettings
            configurationLoading={configurationLoading}
            deviceInfo={deviceInfo}
            onSettingsChange={this.handleSettingsChange}
            {...settings}
          />
        ) : (
          <AdvancedSignalsChartSettings
            advancedSignals={deviceInfo.advancedSignals}
            eid={eid}
            onSettingsChange={this.handleSettingsChange}
            updateDeviceInfo={this.updateDeviceInfo}
            {...settings}
          />
        )}
      </React.Fragment>
    )
  }

  render() {
    const { classes } = this.props

    const { eid = '', configurationLoading, devicesButtonTextKey, devicesTableDisplayed } = this.state

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.historicWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
                <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
              </DialogContentText>
              <Button
                className='primary-action-button'
                classes={{ root: classes.devicesButton }}
                onClick={this.handleChangeDevicesTableDisplay}
              >
                {this.formatMessage(messages[devicesButtonTextKey])}
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              {this.renderConfigurationStatus()}
            </Grid>
          </Grid>
          <br />
          {devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={eid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          {this.renderHistoricTypedSettings()}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={configurationLoading} onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

HistoricSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(HistoricSettings))
