import { defineMessages } from 'react-intl'

const namespace = 'GaugeSettings'

const messages = defineMessages({
  gaugeWidget: {
    id: `${namespace}.gaugeWidget`,
    description: 'Gauge widget',
    defaultMessage: 'GAUGE WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  youMustChooseOneSignal: {
    id: `${namespace}.youMustChooseOneSignal`,
    description: '',
    defaultMessage: 'You must choose a valid signal'
  },
  gaugeWidgetSettings: {
    id: `${namespace}.gaugeWidgetSettings`,
    description: 'Gauge widget settings',
    defaultMessage: 'Gauge widget settings.'
  },
  dinamicValue: {
    id: `${namespace}.dinamicValues`,
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  notSupportedMachine: {
    id: `${namespace}.notSupportedMachine`,
    description: '',
    defaultMessage: 'Not supported machine'
  },
  otherGaugeWidgetSettings: {
    id: `${namespace}.otherGaugeWidgetSettings`,
    description: 'Gauge widget settigs dialog',
    defaultMessage: 'Other widget settings:'
  },
  minValue: {
    id: `${namespace}.minValue`,
    description: 'Minimum value',
    defaultMessage: 'Minimum value'
  },
  maxValue: {
    id: `${namespace}.maxValue`,
    description: 'Maximum value',
    defaultMessage: 'Maximum value'
  },
  mustHaveAValue: {
    id: `${namespace}.mustHaveAValue`,
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Gauge settings cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Gauge settings saving button text',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: `${namespace}.noDataAvailable`,
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: `${namespace}.noSignal`,
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  maxNumberOfDecimals: {
    id: `${namespace}.maxNumberOfDecimals`,
    description: 'Number of decimals',
    defaultMessage: 'Max no. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: `${namespace}.onlyZeroOrHigherIsAllowed`,
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  },
  loadingConfiguratedSignals: {
    id: `${namespace}.loadingConfiguratedSignals`,
    description: 'Loading configurated signals',
    defaultMessage: 'Loading configurated signals'
  },
  noDataSince: {
    id: `${namespace}.noDataSince`,
    description: 'No data since',
    defaultMessage: 'No data since {date}'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  maxNumberOfDecimalsCantBeHigherThan: {
    id: `${namespace}.maxNumberOfDecimalsCantBeHigherThan`,
    description: 'Max. no. of decimals can not be higher than',
    defaultMessage: 'Max. no. of decimals can not be higher than: {max}'
  }
})

export default messages
