import { defineMessages } from 'react-intl'

const namespace = 'SelectColumnsButton'

const messages = defineMessages({
  name: {
    id: `${namespace}.name`,
    description: 'Name title',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'eid',
    defaultMessage: 'EID'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Model column',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machine: {
    id: `${namespace}.machine`,
    description: 'Machine',
    defaultMessage: 'Machine'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration text',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version text',
    defaultMessage: 'Device configuration version'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  lastOnline: {
    id: `${namespace}.lastOnline`,
    description: 'Last online',
    defaultMessage: 'Last online'
  },
  deviceSerialNumber: {
    id: `${namespace}.deviceSerialNumber`,
    description: 'Device serial number',
    defaultMessage: 'Device serial number'
  },
  simStatus: {
    id: `${namespace}.simStatus`,
    description: 'SIM status',
    defaultMessage: 'SIM status'
  },
  planID: {
    id: `${namespace}.planID`,
    description: 'Plan ID header title',
    defaultMessage: 'Plan ID'
  },
  accessPassStatus: {
    id: `${namespace}.accessPassStatus`,
    description: 'Access Pass status column header',
    defaultMessage: 'Access Pass status'
  },
  lastTelemetryMessage: {
    id: `${namespace}.lastTelemetryMessage`,
    description: 'Last telemetry message',
    defaultMessage: 'Last telemetry message'
  },
  mobileSignal: {
    id: `${namespace}.mobileSignal`,
    description: 'Mobile signal',
    defaultMessage: 'Mobile signal'
  },
  lastGPSMessage: {
    id: `${namespace}.lastGPSMessage`,
    description: 'Last GPS message',
    defaultMessage: 'Last GPS message'
  },
  lastMessageDate: {
    id: `${namespace}.lastMessageDate`,
    description: 'Last message date',
    defaultMessage: 'Last message date'
  },
  date: {
    id: `${namespace}.date`,
    description: 'Date',
    defaultMessage: 'Date'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description',
    defaultMessage: 'Description'
  },
  machineName: {
    id: `${namespace}.machineName`,
    description: 'Machine name',
    defaultMessage: 'Machine name'
  },
  type: {
    id: `${namespace}.type`,
    description: 'Type',
    defaultMessage: 'Type'
  },
  severity: {
    id: `${namespace}.severity`,
    description: 'Severity',
    defaultMessage: 'Severity'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status',
    defaultMessage: 'Status'
  },
  planExpirationDate: {
    id: `${namespace}.planExpirationDate`,
    description: 'Plan expiration date',
    defaultMessage: 'Plan expiration date'
  }
})

export default messages
